import React, { useCallback, useEffect, useState } from "react";
import { Loader, Modal, ModalProps } from "@app/components";
import styled from "styled-components";
import { theme } from "styled-tools";
import {
  GoogleMapProvider,
  MonitoringProvider,
  useMonitoring,
  useNotification,
} from "@app/providers";
import { KIPProject, KIPVehicleDetails, VehicleTrack } from "@app/models";
import {
  getGEOFence,
  getGEOFences,
  getMonitoringVehicles,
  getVehicleById,
  getVehicleReport,
  getVehicleState,
  getVehicleTrack,
} from "@app/api";
import DrawGEOFence from "../../pages/Monitoring/components/DrawGEOFence";
import { dateStatisticFormat, formatToLocalString } from "@app/helpers";

interface Props extends Pick<ModalProps, "onClose" | "open"> {
  projectId?: KIPProject["id"];
  vehicleDetails: KIPVehicleDetails | null;
  vehicleId: string;
  vehicleGovNumber: string;
}

const StyledMap = styled.div`
  width: calc(100% + 42px);
  height: 90vh;
  background-color: ${theme("color.white")};
  position: relative;
  margin: -16px -24px;
  border: 3px solid black;
`;

const StyledLoaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Map(
  props: Pick<
    Props,
    "vehicleDetails" | "vehicleId" | "vehicleGovNumber" | "projectId"
  >
) {
  const { vehicleId, vehicleGovNumber, vehicleDetails, projectId } = props;
  const {
    setVehicle,
    hideControls,
    setGEOFence,
    geoFenceDetail,
    setGEOFenceLatLngList,
    setModalVisible,
    setVehicleReport,
    vehicleReport,
  } = useMonitoring();
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);

  const getTrackData = useCallback(
    async (from: Date, to: Date) => {
      try {
        const { data: dataTrack, errors } = await getVehicleTrack(vehicleId, {
          fromTime: Math.floor(from.getTime() / 1000),
          toTime: Math.floor(to.getTime() / 1000),
        });

        if (!!errors && errors.length > 0) {
          showNotification({
            variant: "error",
            message:
              typeof errors !== "string"
                ? errors[0]
                : `Ошибка при получении данных от провайдера`,
          });
        }

        return dataTrack.points;
      } catch (e) {
        console.log(e);
        showNotification({
          variant: "error",
          message: `При загрузке данных трэка произошла ошибка`,
        });
        setPending(false);
        return [];
      }
    },
    [showNotification, vehicleId]
  );

  const fetchVehicleReport = useCallback(
    async (id: string, fromDate: Date, toDate: Date) => {
      const { data: vehicleReportData } = await getVehicleReport(id, {
        fromDateTime: dateStatisticFormat(fromDate, "yyyy-MM-dd HH:mm"),
        toDateTime: dateStatisticFormat(toDate, "yyyy-MM-dd HH:mm"),
      });
      setVehicleReport(vehicleReportData);
    },
    [setVehicleReport]
  );

  const getData = useCallback(async () => {
    try {
      setPending(true);
      setVehicle(null);

      const fromDateTime = formatToLocalString(
        vehicleDetails!.workStartDateTime
      );
      const toDateTime = formatToLocalString(vehicleDetails!.workEndDateTime);

      const fromDate = new Date(vehicleDetails!.workStartDateTime);
      const toDate = new Date(vehicleDetails!.workEndDateTime);

      const { data: vehiclesData } = await getMonitoringVehicles({
        govNumber: vehicleGovNumber,
        pageNumber: 1,
        pageSize: 1,
        dateMin: fromDate.toJSON(),
        dateMax: toDate.toJSON(),
      });
      await fetchVehicleReport(
        vehicleId,
        new Date(fromDateTime),
        new Date(toDateTime)
      );

      const { data: stateData } = await getVehicleState(vehicleId);

      const trackPoints = await getTrackData(fromDate, toDate);

      const resGeoFenceList = await getGEOFences({
        pageSize: 20,
        pageNumber: 1,
        projectId,
      });

      if (resGeoFenceList?.data?.length) {
        const [geoFence] = resGeoFenceList.data;
        const { data: dataGeofence } = await getGEOFence(geoFence.id);

        setGEOFence(dataGeofence);
        const latLngList = dataGeofence.coordinates.map(
          ([lat, lng]) => new google.maps.LatLng(lat, lng)
        );
        setGEOFenceLatLngList(latLngList);
        setModalVisible(true);
      }

      setPending(false);

      const trackPointsLength = trackPoints.length;

      if (trackPointsLength === 0) {
        showNotification({
          variant: "warning",
          message: `Трек отсутствует!\nНе удалось загрузить историю движения`,
        });
      }

      let vehicleTrack: VehicleTrack;

      if (vehiclesData && trackPoints.length) {
        const [vehicle] = vehiclesData.vehicles;

        vehicleTrack = {
          ...vehicle,
          dateMin: fromDate,
          dateMax: toDate,
          points: trackPoints,
          ...(stateData || {
            address: "",
            dateTime: trackPoints[trackPointsLength - 1].dateTime,
            speed: trackPoints[trackPointsLength - 1].speed,
            latitude: trackPoints[trackPointsLength - 1].latitude,
            longitude: trackPoints[trackPointsLength - 1].longitude,
          }),
        };
      } else {
        // vehiclesData иногда возвращает null, поэтому берем данные по технике из getVehicleById
        const response = await getVehicleById(vehicleId);
        const { imeiGps, model, type, characteristic, ...rest } = response.data;

        vehicleTrack = {
          ...rest,
          characteristics: characteristic.name,
          gpsDataUpdatedStatusId: 0,
          imeigps: imeiGps,
          name: `${type.name} ${rest.govNumber}`,
          vehicleModel: model,
          vehicleType: type.name,
          // @ts-ignore отсутствуют ндс, тип компании
          companies: rest.companies.map(({ company }) => ({
            ...company,
          })),
          dateMin: fromDate,
          dateMax: toDate,
          points: trackPoints,
          ...(stateData || {
            address: "",
            dateTime: trackPoints[trackPointsLength - 1].dateTime,
            speed: trackPoints[trackPointsLength - 1].speed,
            latitude: trackPoints[trackPointsLength - 1].latitude,
            longitude: trackPoints[trackPointsLength - 1].longitude,
          }),
        };
      }

      setVehicle(vehicleTrack);
    } catch (e) {
      console.log(e);
      showNotification({
        variant: "error",
        message: `При загрузке данных произошла ошибка`,
      });
      setPending(false);
    }
  }, [
    setVehicle,
    vehicleDetails,
    vehicleGovNumber,
    vehicleId,
    getTrackData,
    projectId,
    setGEOFence,
    setGEOFenceLatLngList,
    setModalVisible,
    showNotification,
  ]);

  useEffect(() => {
    hideControls(true);

    getData();
  }, [getData, hideControls]);

  return (
    <>
      {pending && (
        <StyledLoaderContainer>
          <Loader />
        </StyledLoaderContainer>
      )}
      {!!geoFenceDetail && <DrawGEOFence />}
    </>
  );
}

export default function (props: Props) {
  const {
    open,
    onClose,
    vehicleDetails,
    vehicleId,
    vehicleGovNumber,
    projectId,
  } = props;

  if (!vehicleDetails || !open) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title=""
      hideHeader={true}
      position="center"
      customSize="90vw"
      zIndex={10000}
    >
      <StyledMap>
        <GoogleMapProvider>
          <MonitoringProvider>
            <Map
              vehicleDetails={vehicleDetails}
              vehicleId={vehicleId}
              vehicleGovNumber={vehicleGovNumber}
              projectId={projectId}
            />
          </MonitoringProvider>
        </GoogleMapProvider>
      </StyledMap>
    </Modal>
  );
}
