import React, { memo, useCallback, useMemo, useState } from "react";
import { dateFormat, getFullName } from "@app/helpers";
import { TemplateSearch } from "@app/templates";
import { adhesionContractById, getTasks } from "@app/api";
import { Checkbox, Chip, ChipColor } from "@app/components";
import styled from "styled-components";
import { Adhesion, AdhesionStatusName, Task } from "@app/models";
import TaskStatusChip from "../TaskStatusChip/TaskStatusChip";
import { ModalAdhesionTaskDetail } from "@app/modals";
import { NcaLayerClientProvider } from "@app/providers";

const StyledCheckbox = styled.div`
  margin-top: -64px;
`;

type TaskContractAdhesion = Task & {
  contract?: Adhesion;
};

function Accession() {
  const [onlyUnClosedTasks, setOnlyUnClosedTasks] = useState<boolean>(true);
  const [selectedTask, setSelectedTask] = useState<TaskContractAdhesion | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const fetchAdhesionContractData = useCallback(async (tasks: Task[]) => {
    try {
      const sourceIds = tasks.map((task) => task.sourceId);
      const promises = sourceIds.map((id) => adhesionContractById(id));
      const responses = await Promise.allSettled(promises);
      const values = responses.reduce(
        (acc: (Adhesion | null)[], cur) => [
          ...acc,
          cur.status === "fulfilled" ? cur.value.data : null,
        ],
        []
      );
      return values.filter(Boolean) as Adhesion[];
    } catch (error) {
      console.error("Error fetching adhesion contract data:", error);
      return [];
    }
  }, []);

  const getStatusVariant = (status: number): ChipColor => {
    switch (status) {
      case 10:
        return "primary";
      case 20:
        return "success";
      default:
        return "primary";
    }
  };

  const onTaskClick = useCallback((item: TaskContractAdhesion) => {
    setSelectedTask(item);
  }, []);

  const onModalClose = useCallback((needUpdate?: boolean) => {
    setSelectedTask(null);
    if (needUpdate) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }, []);

  const mapTableData = useCallback((item: TaskContractAdhesion) => {
    // Получение списка задач. Проверяем наличие item.contract
    if (!item || !item.contract) {
      return [];
    }
    return [
      item.name, // "Подписание договора присоединения",
      <Chip
        text={AdhesionStatusName[item.contract.statusId]}
        color={getStatusVariant(item.contract.statusId)}
      />,
      dateFormat(item.dateStart, "dd.MM.yyyy "),
      dateFormat(item.dateFinish, "dd.MM.yyyy "),
      <TaskStatusChip status={item.status} />,
      getFullName(item.executorUser),
      getFullName(item.initiator),
    ];
  }, []);

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOnlyUnClosedTasks(e.target.checked);
    },
    []
  );

  const tableLabels = useMemo(
    () => [
      "Наименование задачи",
      "Статус договора",
      "Дата назначения",
      "Дата исполнения",
      "Статус задачи",
      "Исполнитель задачи",
      "Инициатор",
    ],
    []
  );

  const getDataWithParams = useCallback(
    async (params: any) => {
      const tasks = await getTasks({
        ...params,
        ForModuleId: 4,
        isUnfinished: onlyUnClosedTasks,
      });
      if (tasks && tasks.data && tasks.data.length > 0) {
        const adhesionData = await fetchAdhesionContractData(tasks.data);
        const taskWithContractAdhesion: TaskContractAdhesion[] = tasks.data.map(
          (task) => {
            const findAdhesion = adhesionData.find(
              (adh) => task.sourceId === adh.id
            );
            if (findAdhesion) {
              return {
                ...task,
                contract: findAdhesion,
              };
            }
            return { ...task };
          }
        );

        return {
          data: taskWithContractAdhesion,
          recordsTotal: tasks.recordsTotal,
          recordsFiltered: tasks.recordsFiltered,
          pageNumber: tasks.pageNumber,
          pageSize: tasks.pageSize,
          succeeded: tasks.succeeded,
          message: tasks.message,
          errors: tasks.errors,
        };
      }
      return {
        data: [],
        recordsTotal: tasks.recordsTotal,
        recordsFiltered: tasks.recordsFiltered,
        pageNumber: tasks.pageNumber,
        pageSize: tasks.pageSize,
        succeeded: tasks.succeeded,
        message: tasks.message,
        errors: tasks.errors,
      };
    },
    [fetchAdhesionContractData, onlyUnClosedTasks]
  );

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      <TemplateSearch<TaskContractAdhesion>
        toolbarProps={{
          searchPlaceholder:
            "Задача, инициатор исполнитель, исполнитель задачи",
          bottomControls: [
            <StyledCheckbox>
              <Checkbox
                label="Только незакрытые задачи"
                checked={onlyUnClosedTasks}
                onChange={handleCheckboxChange}
              />
            </StyledCheckbox>,
          ],
        }}
        getData={getDataWithParams}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onTaskClick}
      />
      {selectedTask && (
        <NcaLayerClientProvider>
          <ModalAdhesionTaskDetail
            open={!!selectedTask}
            task={selectedTask as Task}
            contract={selectedTask?.contract}
            onClose={onModalClose}
          />
        </NcaLayerClientProvider>
      )}
    </>
  );
}

export default memo(Accession);
