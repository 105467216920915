import {
  addMinutes,
  format,
  lastDayOfMonth,
  parseISO,
  startOfMonth,
} from "date-fns";

export function dateFormat(
  date: string | Date | null,
  dateFormat: string
): string {
  try {
    if (!date) {
      return "—";
    }
    if (typeof date === "string") {
      return format(new Date(date), dateFormat);
    }

    return format(date, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export function dateFormatWithTimezone(
  date: string | Date | null,
  dateFormat: string
): string {
  try {
    if (!date) {
      return "—";
    }

    let parsedDate: Date;

    if (typeof date === "string") {
      const timezoneOffset = date.slice(-6); // Извлечь часовой пояс (например, "+05:00" или "-03:00")
      const [hoursOffset, minutesOffset] = timezoneOffset
        .split(":")
        .map((part) => parseInt(part, 10));
      const totalMinutesOffset = hoursOffset * 60 + minutesOffset;

      parsedDate = addMinutes(parseISO(date), totalMinutesOffset);
    } else {
      parsedDate = date;
    }

    return format(parsedDate, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export function dateFormatOffset(
  date: string | Date | null | undefined,
  dateFormat = "dd.MM.yyyy HH:mm"
): string {
  try {
    if (!date) {
      return "—";
    }

    if (typeof date === "string") {
      const dt = new Date(date);
      const time = dt.getTime();
      // offset in milliseconds
      const offset = dt.getTimezoneOffset() * 60 * 1000;
      return format(time - offset, dateFormat);
    }

    return format(date, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export function dateUTCFormat(
  date: string | Date | null,
  dateFormat: string
): string {
  try {
    if (!date) {
      return "—";
    }

    let adjustedDate;
    if (typeof date === "string") {
      if (date.endsWith("Z")) {
        const parsedDate = new Date(date);
        // -5 hours
        adjustedDate = new Date(parsedDate.getTime() - 5 * 60 * 60 * 1000);
      } else {
        adjustedDate = new Date(date);
      }
    } else {
      adjustedDate = date;
    }

    return format(adjustedDate, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export function dateStatisticFormat(
  date: string | Date | null,
  dateFormat: string
): string {
  try {
    if (!date) {
      return "—";
    }

    let adjustedDate;
    if (typeof date === "string") {
      if (date.endsWith("Z")) {
        const parsedDate = new Date(date);
        // -5 hours
        adjustedDate = new Date(parsedDate.getTime() - 5 * 60 * 60 * 1000);
      } else {
        adjustedDate = new Date(date);
      }
    } else {
      adjustedDate = date;
    }

    return format(adjustedDate, dateFormat);
  } catch (e) {
    return "Неверный формат даты";
  }
}

export const getFirstDayOfMonth = (date: Date = new Date()) => {
  return startOfMonth(date);
};
export const getLastDayOfMonth = (date: Date = new Date()) => {
  return lastDayOfMonth(date);
};

export const attachTimeAndDate = (dateVal: Date, timeVal: string): string => {
  let date = new Date(dateVal.getTime());
  const timeParts = timeVal.split(":");
  date.setHours(Number(timeParts[0]), Number(timeParts[1]), 0, 0);
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  return date.toJSON();
};

export const formatToUTCString = (dateInput: string | Date): string => {
  let date: Date;

  if (typeof dateInput === "string") {
    date = new Date(dateInput);
  } else if (dateInput instanceof Date) {
    date = dateInput;
  } else {
    throw new Error("Invalid date input");
  }

  // Преобразуем дату в строку фиксированного формата
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  // Возвращаем строку в формате "YYYY-MM-DD HH:mm"
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const formatToLocalString = (dateInput: string | Date): string => {
  if (typeof dateInput === "string") {
    // Убираем временную зону из строки
    const match = dateInput.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/);
    if (match) {
      const dateWithoutTimezone = match[1]; // Получаем часть без смещения
      return dateWithoutTimezone.replace("T", " ");
    }
    throw new Error("Invalid date format");
  } else if (dateInput instanceof Date) {
    // Если это объект Date, то форматируем его без смещения
    const year = dateInput.getFullYear();
    const month = String(dateInput.getMonth() + 1).padStart(2, "0");
    const day = String(dateInput.getDate()).padStart(2, "0");
    const hours = String(dateInput.getHours()).padStart(2, "0");
    const minutes = String(dateInput.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  throw new Error("Invalid date input");
};
