export const validateIIN = (iin: string): boolean => {
  if (!/^\d{12}$/.test(iin)) {
    console.log("ИИН не соответствует формату (12 цифр).");
    return false;
  }

  const weights1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const weights2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
  const digits = iin.split("").map(Number);

  // Первый цикл
  let controlSum =
    digits
      .slice(0, 11)
      .reduce((sum, digit, i) => sum + digit * weights1[i], 0) % 11;

  if (controlSum === 10) {
    // Второй цикл
    controlSum =
      digits
        .slice(0, 11)
        .reduce((sum, digit, i) => sum + digit * weights2[i], 0) % 11;
  }

  return controlSum !== 10 && controlSum === digits[11];
};

export const validateBIN = (bin: string): boolean => {
  if (!/^\d{12}$/.test(bin)) {
    console.error("БИН должен состоять из 12 цифр.");
    return false;
  }

  const digits = bin.split("").map(Number);

  // Проверка месяца (2-4 разряды)
  const month = parseInt(bin.slice(2, 4));
  if (month < 1 || month > 12) {
    console.error("Некорректный месяц в БИН.");
    return false;
  }

  // Пятая цифра должна быть 4, 5 или 6
  const type = digits[4];
  if (![4, 5, 6].includes(type)) {
    console.error("Некорректный тип организации в БИН (пятая цифра).");
    return false;
  }

  // Шестая цифра должна быть 0, 1, 2 или 4
  const specialSign = digits[5];
  if (![0, 1, 2, 4].includes(specialSign)) {
    console.error("Некорректный специальный признак в БИН (шестая цифра).");
    return false;
  }

  // Проверка контрольного разряда
  const weights1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const weights2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
  let controlSum =
    digits
      .slice(0, 11)
      .reduce((sum, digit, i) => sum + digit * weights1[i], 0) % 11;

  if (controlSum === 10) {
    controlSum =
      digits
        .slice(0, 11)
        .reduce((sum, digit, i) => sum + digit * weights2[i], 0) % 11;
  }

  return controlSum !== 10 && controlSum === digits[11];
};
