import {
  memo,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import {
  IconChevronUp24,
  IconDay24,
  IconNight24,
  IconPlay24,
  IconPrint30,
} from "@app/icons";
import {
  Button,
  DateTimePicker,
  Grid,
  Info,
  TextField,
  TimePicker,
  Tooltip,
  useForm,
} from "@app/components";
import {
  attachTimeAndDate,
  currencyFormat,
  dateFormat,
  getAxiosErrorMessage,
  HOURS_UNIT_ID,
  isPartner,
  KM_UNIT_ID,
  M2_UNIT_ID,
  M3_UNIT_ID,
  M_UNIT_ID,
  MONTH_UNIT_ID,
  NIGHT_SHIFT_ID,
  PIECE_UNIT_ID,
  POG_M_UNIT_ID,
  RACE_UNIT_ID,
  setMask,
  SHIFT_UNIT_ID,
  TN_KM_UNIT_ID,
  TN_UNIT_ID,
} from "@app/helpers";
import {
  addCommentInJournal,
  APIResponse,
  getVehicleJournalById,
  JournalCommentCreate,
  loadVehicleJournalData,
  setVehicleJournalConfirmed,
  setVehicleJournalNotWork,
  setVehicleJournalNotWorkFurther,
  updateCommentInJournal,
} from "@app/api";
import {
  KIPVehicleDetails,
  Unit,
  VehicleJournalCommentDto,
  VehicleJournalDetail,
  VehicleJournalFile,
  VehicleJournalList,
  VehicleJournalNotWorkRequest,
  VehicleModel,
  VehicleType,
  VehicleZhurnalState,
  WorkShiftTime,
} from "@app/models";
import classNames from "classnames";
import { useNotification, useUser } from "@app/providers";
import { AxiosError } from "axios";
import {
  ModalJournalComments,
  ModalKIPVehicleTrack,
  ModalVehicleJournalFiles,
} from "@app/modals";
import styled from "styled-components";
import { journalSchema } from "./helper";
import { theme } from "styled-tools";

interface Props {
  data: VehicleJournalDetail;
  headerInfo: VehicleJournalList | null;
  filterTypeIndex: number;
  expanded?: boolean;
  onConfirm: (needUpdate?: boolean) => void;
  onGovNumberClick: (value?: string) => void;
  onShiftClick: (code?: string) => void;
}

interface Data {
  vehicleType: VehicleType | null;
  vehicleModel: VehicleModel | null;
  unitShort: Unit | null;
  startDate: Date | null;
  startTime: string | null;
  endDate: Date | null;
  endTime: string | null;
  workPerformed?: string;
  cargoWeight?: string;
  distance?: string;
  workShift?: WorkShiftTime;
  // comment?: string;
  files?: string[];
}

const MenuWrapper = styled.div`
  position: relative;
`;

const MenuContainer = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
`;

const MenuItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;

  font-size: 14px;
  line-height: 20px;
  text-align: left;

  width: 242px;
  height: 52px;

  background: #ffffff;
  border-radius: 4px;
  border: none;

  cursor: pointer;

  &:hover {
    background: ${theme("color.grayLight")};
  }
`;

const CommentBlock = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    p {
      text-decoration: underline;
    }

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  }
`;

function VehicleWorkingCard(props: Props) {
  const {
    data,
    headerInfo,
    filterTypeIndex,
    expanded = true,
    onConfirm,
    onGovNumberClick,
    onShiftClick,
  } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [journalData, setJournalData] = useState<VehicleJournalDetail>(data);
  const [isOpen, setIsOpen] = useState(expanded);
  const [isFilesModalShowing, setIsFilesModalShowing] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const userIsPartner = !!(user && isPartner(user.role));
  const [vehicleDetails, setVehicleDetails] =
    useState<KIPVehicleDetails | null>(null);
  const [openComments, setOpenComments] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [findComment, setFindComment] = useState<VehicleJournalCommentDto>();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const isNightShift = useMemo(
    () => data.shiftType?.id === NIGHT_SHIFT_ID,
    [data.shiftType?.id]
  );

  useEffect(() => {
    if (isNightShift) {
      const _minDate = new Date(data.workDate);
      const _maxDate = new Date(data.workDate);
      _minDate.setDate(_minDate.getDate() - 1);
      _maxDate.setDate(_maxDate.getDate() + 1);
      setMinDate(_minDate);
      setMaxDate(_maxDate);
    }
  }, [data.workDate, isNightShift]);

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  useEffect(() => {
    const find = journalData.comments.find((item) => item.userId === user?.id);
    setFindComment(find);
    if (find) {
      setCommentValue(find.comment);
    }
  }, [journalData.comments, user?.id]);

  function setWorkDate(date: string, isEnd?: boolean): Date | null {
    if (date) {
      return new Date(date);
    }
    if (data.stateId === VehicleZhurnalState.SAVE) {
      const workDate = new Date(data.workDate);
      if (data.shiftType.id === NIGHT_SHIFT_ID && isEnd) {
        workDate.setDate(workDate.getDate() + 1);
      }
      return workDate;
    }
    return null;
  }

  function setWorkTime(date: string | null): string | null {
    if (data?.stateId === VehicleZhurnalState.SAVE) {
      return null;
    }
    // TODO: set initial time of shifts
    if (date) {
      const dt = new Date(date);

      return dateFormat(
        new Date(dt.getTime()), //  + dt.getTimezoneOffset() * 60000
        "HH:mm"
      );
    }

    return null;
  }

  const onCommentChange = useCallback((value: string) => {
    setCommentValue(value || "");
  }, []);

  const updateJournal = useCallback(async () => {
    const journalUpdated = await getVehicleJournalById(journalData.id);
    setJournalData(journalUpdated.data);
  }, [journalData.id]);

  const {
    values,
    errors,
    pending,
    validate,
    setErrorMessage,
    onChange,
    setPending,
  } = useForm<Data>({
    values: {
      vehicleType: null,
      vehicleModel: null,
      unitShort: data.unitShort,
      startDate: setWorkDate(data.workStartDateTime || data.workDate || ""),
      endDate: setWorkDate(data.workEndDateTime || data.workDate || "", true),
      startTime: setWorkTime(data.workStartDateTime || data.workDate),
      endTime: setWorkTime(data.workEndDateTime || data.workDate),
      workPerformed: data.workPerformed ?? "",
      workShift: data.workShift ?? "",
      // comment: data.comment ?? "",
      files: [],
    },
    schema: journalSchema,
  });

  const onLoadData = useCallback(async () => {
    if (
      !values.startDate ||
      !values.endDate ||
      !values.startTime ||
      !values.endTime
    ) {
      const msg: any = {};
      if (!values.startDate) {
        msg.startDate = "Укажите начальную дату";
      }
      if (!values.endDate) {
        msg.endDate = "Укажите конечную дату";
      }
      if (!values.startTime) {
        msg.startTime = "Укажите время начала";
      }
      if (!values.endTime) {
        msg.endTime = "Укажите время завершения";
      }
      setErrorMessage(msg);
      return;
    }

    try {
      const body = {
        vehicleJurnalId: journalData.id,
        vehicleId: journalData.vehicle.id,
        startTime: attachTimeAndDate(values.startDate!, values.startTime!),
        endTime: attachTimeAndDate(values.endDate!, values.endTime!),
      };
      setPending(true);
      await loadVehicleJournalData(body);

      await updateJournal();

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    journalData.id,
    journalData.vehicle.id,
    setErrorMessage,
    setPending,
    showNotification,
    updateJournal,
    values.endDate,
    values.endTime,
    values.startDate,
    values.startTime,
  ]);

  const addComment = useCallback(async () => {
    if (!commentValue) {
      return;
    }
    try {
      setPending(true);

      let response;

      if (findComment) {
        response = await updateCommentInJournal({
          id: findComment.id,
          comment: commentValue,
        });
      } else {
        const body: JournalCommentCreate = {
          vehicleJournalId: journalData.id,
          comment: commentValue,
        };

        response = await addCommentInJournal(body);
      }

      setPending(false);
      await updateJournal();
      if (response?.succeeded) {
        showNotification({
          message: "Комментарий сохранен",
          variant: "success",
        });
      }
    } catch (e) {
      setPending(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    commentValue,
    findComment,
    journalData.id,
    setPending,
    showNotification,
    updateJournal,
  ]);

  const onDidntWorkedClick = useCallback(
    async (isFurther = false) => {
      setShowMenu(false);
      onConfirm(true);
      try {
        setPending(true);
        const form: VehicleJournalNotWorkRequest = {
          id: journalData.id,
          // comment: values.comment || "",
          // files: [],
        };
        let response;
        if (isFurther) {
          response = await setVehicleJournalNotWorkFurther(form);
        } else {
          response = await setVehicleJournalNotWork(form);
        }

        if (response.data) {
          const { stateId } = response.data;
          setJournalData({ ...journalData, stateId });
        }

        await addComment();
        setPending(false);
      } catch (e) {
        setPending(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [addComment, journalData, onConfirm, setPending, showNotification]
  );

  const onConfirmClick = useCallback(async () => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }

    // добавлено для теста задачи (DN-367)
    await onLoadData();

    try {
      setPending(true);

      const { id, vehicle, cargoWeight, distance } = journalData;
      const body = {
        id,
        vehicleId: vehicle.id,
        workStartDateTime: attachTimeAndDate(
          values.startDate!,
          values.startTime!
        ),
        workEndDateTime: attachTimeAndDate(values.endDate!, values.endTime!),
        workPerformed: values.workPerformed,
        stateId: VehicleZhurnalState.CONFIRMED,
        // files: [],
      };
      Object.assign(body, cargoWeight, distance);

      const response = await setVehicleJournalConfirmed(body);
      setJournalData(response.data);

      await addComment();
      setPending(false);
      onConfirm(true);
    } catch (e) {
      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
      setPending(false);
    }
  }, [
    addComment,
    journalData,
    onConfirm,
    onLoadData,
    setPending,
    showNotification,
    validate,
    values.endDate,
    values.endTime,
    values.startDate,
    values.startTime,
    values.workPerformed,
  ]);

  // комментирование партнерам доступно при условии, что canCommentJournal = true
  const canCommentJournal = useMemo(() => {
    return !userIsPartner || journalData.partner.canCommentJournal;
  }, [journalData.partner.canCommentJournal, userIsPartner]);

  const getWorkPerformedInput = useMemo(
    function () {
      const attrs = {
        label: "Фактическое время работы",
        placeholder: "Укажите количество часов",
        name: "workPerformed",
        value: values.workPerformed,
        error: !!errors.workPerformed,
        helperText: errors.workPerformed,
        disabled: userIsPartner || pending,
        onChange: onChange,
      };
      switch (journalData.unitShort?.id) {
        case MONTH_UNIT_ID:
          return null;
        case RACE_UNIT_ID:
          return (
            <TextField
              {...attrs}
              label="Количество рейсов"
              placeholder="Укажите количество рейсов"
              type={"number"}
              onChange={onChange}
            />
          );
        case TN_KM_UNIT_ID:
          return (
            <div className={"flex-row"}>
              <TextField
                {...attrs}
                name="cargoWeight"
                label="Вес груза, т"
                placeholder="Укажите значение"
                type={"number"}
                value={values.cargoWeight}
                error={!!errors.cargoWeight}
                helperText={errors.cargoWeight}
              />
              <TextField
                {...attrs}
                name="distance"
                label="Расстояние, км"
                placeholder="Укажите значение"
                type={"number"}
                value={values.distance}
                error={!!errors.distance}
                helperText={errors.distance}
              />
            </div>
          );
        case SHIFT_UNIT_ID:
          return (
            <TextField
              {...attrs}
              label="Коэффициент смены"
              placeholder="Укажите от 0.01 до 1"
              onChange={(value, name) => {
                const formatted = setMask(value.replace(/\D/g, ""), "#.##");
                onChange(Number(formatted) > 1 ? "1" : formatted, name);
              }}
            />
          );
        case HOURS_UNIT_ID:
          return (
            <TextField
              {...attrs}
              placeholder="00:00"
              timeFormat={true}
              onChange={(value, name) =>
                onChange(setMask(value.replace(/\D/g, ""), "##:##"), name)
              }
            />
          );
        case POG_M_UNIT_ID:
        case PIECE_UNIT_ID:
        case TN_UNIT_ID:
        case KM_UNIT_ID:
        case M3_UNIT_ID:
        case M2_UNIT_ID:
        case M_UNIT_ID:
          return (
            <TextField
              {...attrs}
              label="Укажите количество"
              placeholder="0.00"
              type={"number"}
              onChange={onChange}
            />
          );
        default:
          return <TextField {...attrs} />;
      }
    },
    [
      errors.cargoWeight,
      errors.distance,
      errors.workPerformed,
      journalData.unitShort?.id,
      onChange,
      pending,
      userIsPartner,
      values.cargoWeight,
      values.distance,
      values.workPerformed,
    ]
  );

  const getDateDiff = useMemo(() => {
    const today = new Date();
    const workDate = new Date(journalData.workDate);
    const diff = Math.abs(today.getTime() - workDate.getTime());
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
  }, [journalData.workDate]);

  const getPriceWithNDS = useCallback(
    (price: number | string | null) => {
      if (!price) {
        return "";
      }
      let _price = currencyFormat(price);
      if (journalData.ndsShortDto?.isNds) {
        _price += " С НДС ";
      }

      _price += ` ${journalData.ndsShortDto?.name}`;

      return _price;
    },
    [journalData.ndsShortDto?.isNds, journalData.ndsShortDto?.name]
  );

  const getTotal = useMemo(() => {
    if (!journalData) {
      return 0;
    }
    const { ndsShortDto, priceWithNds, priceWithOutNds, unitShort } =
      journalData;
    const price = (ndsShortDto?.isNds ? priceWithNds : priceWithOutNds) || 0;
    let amount = +(values.workPerformed || 0);
    if (unitShort.id === MONTH_UNIT_ID) {
      amount = 1;
    } else if (unitShort.id === HOURS_UNIT_ID) {
      const timeParts = values.workPerformed?.split(":");
      if (timeParts && timeParts.length === 2) {
        const timeAmount = +timeParts[0] + +timeParts[1] / 60;
        amount = Math.round((timeAmount + Number.EPSILON) * 100) / 100;
      }
    }
    return getPriceWithNDS(price * amount);
  }, [getPriceWithNDS, journalData, values.workPerformed]);

  const getShiftIcon = useCallback(() => {
    let tooltipText = isNightShift ? "Ночная смена" : "Дневная смена";

    if (journalData.workShift) {
      const { name } = journalData.workShift;
      tooltipText = tooltipText.concat(" (", name, ")");
    }

    return (
      <Tooltip content={tooltipText} direction={"right"}>
        {isNightShift ? <IconNight24 /> : <IconDay24 />}
      </Tooltip>
    );
  }, [isNightShift, journalData.workShift]);

  function useOutsideAlerter(ref: RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
          setShowMenu(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onPlayClick = useCallback(() => {
    // для отображения техники в модалке мониторинга используется только 2 параметра
    if (!!data.workEndDateTime && !!data.workStartDateTime) {
      setVehicleDetails({
        workStartDateTime: data.workStartDateTime,
        workEndDateTime: data.workEndDateTime,
      } as KIPVehicleDetails);
    } else if (values.startTime && values.endTime) {
      const workStartDateTime = attachTimeAndDate(
        values.startDate!,
        values.startTime
      );
      const workEndDateTime = attachTimeAndDate(
        values.endDate!,
        values.endTime
      );
      setVehicleDetails({
        workStartDateTime,
        workEndDateTime,
      } as KIPVehicleDetails);
    }
  }, [
    data.workEndDateTime,
    data.workStartDateTime,
    values.endDate,
    values.endTime,
    values.startDate,
    values.startTime,
  ]);

  const onCloseTrackModal = useCallback(() => {
    setVehicleDetails(null);
  }, []);

  const onCloseComments = useCallback(
    async (needUpdate?: boolean) => {
      if (needUpdate) {
        await updateJournal();
      }
      setOpenComments(false);
    },
    [updateJournal]
  );

  const showCommentsModal = useCallback(() => setOpenComments(true), []);

  const govNumberClicked = useCallback(
    () => onGovNumberClick(journalData?.vehicle.govNumber),
    [journalData?.vehicle.govNumber, onGovNumberClick]
  );

  const shiftTypeClicked = useCallback(
    () => onShiftClick(journalData?.shiftType.code),
    [journalData?.shiftType.code, onShiftClick]
  );

  const onPrintButtonClick = useCallback(() => {
    localStorage.setItem("vehicleData", JSON.stringify(journalData));
    localStorage.setItem("headerInfo", JSON.stringify(headerInfo));
    window.open("/app/vehicle-accounting/detail/printing", "_blank");
  }, [journalData, headerInfo]);

  const onJournalDataUpdate = useCallback(
    (updateFiles?: VehicleJournalFile[]) => {
      if (updateFiles) {
        setJournalData({
          ...journalData,
          files: updateFiles,
        });
      }
    },
    [journalData]
  );

  return (
    <div className="working-card">
      <div className="working-card__header">
        <span
          className="working-card__govnum"
          title={`Фильтровать по госномеру "${journalData?.vehicle.govNumber}"`}
          onClick={govNumberClicked}
        >
          {journalData?.vehicle.govNumber}
        </span>
        <div
          className="working-card__shift"
          title="Фильтровать по смене"
          onClick={shiftTypeClicked}
        >
          {getShiftIcon()}
        </div>
        <div
          className="working-card__title"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <span>
            {[
              filterTypeIndex > 0 &&
                dateFormat(journalData.workDate, "dd.MM.yyyy"),
              journalData.vehicleType?.name,
              journalData.vehicleCharacteristic?.name,
              journalData.serviceType?.name,
              journalData.unitShort?.name,
              journalData.workPerformed,
            ]
              .filter(Boolean)
              .join(" · ")}
          </span>
        </div>
        {!userIsPartner && (
          <button
            disabled={!values.startDate || !values.endDate || pending}
            className="working-card__fill-btn"
            onClick={onLoadData}
          >
            Заполнить данные
          </button>
        )}
        <button
          className={classNames("working-card__play-btn", {
            "working-card__play-btn--confirmed":
              journalData.stateId === VehicleZhurnalState.CONFIRMED,
            "working-card__play-btn--didnt-work":
              journalData.stateId === VehicleZhurnalState.DID_NOT_WORK,
            "working-card__play-btn--saved":
              journalData.stateId === VehicleZhurnalState.SAVE,
            "working-card__play-btn--consideration":
              journalData.stateId === VehicleZhurnalState.CONSIDERATION,
            "working-card__play-btn--expired":
              journalData.stateId === VehicleZhurnalState.SAVE &&
              getDateDiff > 3,
          })}
          onClick={onPlayClick}
        >
          <IconPlay24 />
        </button>
        {journalData.stateId === VehicleZhurnalState.CONFIRMED && (
          <IconPrint30 onClick={onPrintButtonClick} />
        )}
        <button
          className="working-card__play-btn"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <IconChevronUp24 />
        </button>
      </div>
      <div
        className={classNames("working-card__content", {
          "working-card__content--open": isOpen,
        })}
      >
        <div className="working-card__cols">
          <div className="working-card__col">
            <p className="working-card__info">
              <span className="working-card__info--label">Контрагент</span>
              <span className="working-card__info--title">
                {journalData.partner?.name}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">Цена заказчика</span>
              <span className="working-card__info--title">
                {getPriceWithNDS(
                  journalData.ndsShortDto?.isNds
                    ? journalData.priceWithNds
                    : journalData.priceWithOutNds
                )}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">Цена партнера</span>
              <span className="working-card__info--title">
                {getPriceWithNDS(
                  journalData.ndsShortDto?.isNds
                    ? journalData.priceWithNds
                    : journalData.priceWithOutNds
                )}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">Моточасы GPS</span>
              <span className="working-card__info--title">
                {journalData.hoursGps
                  ? journalData.hoursGps.toString().slice(0, -3)
                  : 0}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">
                Длительность смены
              </span>
              <span className="working-card__info--title">
                {journalData.shiftDuration
                  ? journalData.shiftDuration.slice(0, -3)
                  : 0}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">Сумма</span>
              <span className="working-card__info--title">{getTotal}</span>
            </p>
          </div>
          <div className="working-card__col">
            <Grid columns={2}>
              <DateTimePicker
                label="Дата начала"
                name="startDate"
                onChange={onChange}
                value={values.startDate}
                error={!!errors.startDate}
                helperText={errors.startDate}
                disabled={userIsPartner || pending || !isNightShift}
                minDate={minDate}
                maxDate={maxDate}
                hideTime={true}
              />
              <TimePicker
                label="Время начала"
                name="startTime"
                onChange={onChange}
                value={values.startTime}
                error={!!errors.startTime}
                helperText={errors.startTime}
                disabled={userIsPartner || pending}
              />
            </Grid>
            <Grid columns={2}>
              <DateTimePicker
                label="Дата завершения"
                name="endDate"
                onChange={onChange}
                value={values.endDate}
                error={!!errors.endDate}
                helperText={errors.endDate}
                disabled={userIsPartner || pending || !isNightShift}
                minDate={minDate}
                maxDate={maxDate}
                hideTime={true}
              />
              <TimePicker
                label="Время завершения"
                name="endTime"
                onChange={onChange}
                value={values.endTime}
                error={!!errors.endTime}
                helperText={errors.endTime}
                disabled={userIsPartner || pending}
              />
            </Grid>
            {getWorkPerformedInput}
          </div>
        </div>
        <div className="working-card__bottom">
          <div id="inner">
            <div id="inner-info">
              <CommentBlock onClick={showCommentsModal}>
                <Info
                  label={"Комментарий (вид работ и пр.заметки)"}
                  variant={"secondary"}
                  value={journalData.comment}
                  ellipsis
                />
              </CommentBlock>
            </div>
            {!userIsPartner && (
              <TextField
                label="Комментарий (вид работ и пр.заметки)"
                placeholder="Введите текст"
                name="comment"
                value={commentValue}
                multiline={true}
                onChange={onCommentChange}
              />
            )}
          </div>
          {!userIsPartner && (
            <div className="working-card__buttons">
              <Button
                text={
                  journalData.files.length
                    ? `Файлы (${journalData.files.length})`
                    : "Файлы"
                }
                variant="outlined"
                showLoader={pending}
                disabled={pending}
                onClick={() => {
                  setIsFilesModalShowing(true);
                }}
              />
              <MenuWrapper>
                <Button
                  text="Не работал"
                  variant="outlined"
                  showLoader={pending}
                  disabled={pending}
                  onClick={() => setShowMenu((prev) => !prev)}
                />
                {showMenu && (
                  <MenuContainer ref={wrapperRef}>
                    <MenuItem onClick={() => onDidntWorkedClick()}>
                      Не работал в этот день
                    </MenuItem>
                    <MenuItem onClick={() => onDidntWorkedClick(true)}>
                      Не будет работать (удалить навсегда)
                    </MenuItem>
                  </MenuContainer>
                )}
              </MenuWrapper>
              <Button
                text="Подтвердить"
                showLoader={pending}
                disabled={pending}
                onClick={onConfirmClick}
              />
            </div>
          )}
        </div>
      </div>
      <ModalVehicleJournalFiles
        open={isFilesModalShowing}
        vehicleJournalId={data.id}
        files={journalData.files}
        updateJournalData={onJournalDataUpdate}
        onClose={() => {
          setIsFilesModalShowing(false);
        }}
      />
      <ModalKIPVehicleTrack
        open={!!vehicleDetails}
        projectId={headerInfo?.projectId}
        vehicleDetails={vehicleDetails}
        vehicleId={journalData?.vehicle.id}
        vehicleGovNumber={journalData?.vehicle.govNumber}
        onClose={onCloseTrackModal}
      />
      <ModalJournalComments
        zIndex={9999}
        open={openComments}
        title={"Комментарии"}
        journalId={journalData.id}
        comment={commentValue}
        comments={journalData.comments}
        canComment={canCommentJournal}
        onClose={onCloseComments}
        onChange={() => {}}
      />
    </div>
  );
}

export default memo(VehicleWorkingCard);
