import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Container, Grid } from "@app/components";
import {
  IconAppStore,
  IconGooglePlay,
  IconInstagram28,
  IconLogo,
  IconMail24,
  IconPhone24,
  IconTelegram28,
} from "@app/icons";
import { Link } from "react-router-dom";
import { useWindowWide } from "@app/hooks";

interface Props {}

const StyledFooter = styled.footer`
  padding: 40px 0 32px;
  box-sizing: border-box;
  background-color: ${theme("color.light")};
`;

const StyledFooterTop = styled.div`
  padding: 0 0 20px 0;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${theme("color.grayLight")};
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    align-items: center;
  }
`;

const StyledLogoContainer = styled(Link)`
  width: 90px;
  color: inherit;

  > svg {
    width: 100%;
  }
`;

const StyledIconButton = styled.a`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;

  > svg {
    color: ${theme("color.dark")};
  }

  span {
    font-size: 16px;
    line-height: 16px;
    color: ${theme("color.dark")};
  }
`;

const StyledIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${theme("color.dark")};
  border-radius: 12px;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 24px;
  color: #999b9e;
`;

const StyledFooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLinks = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledLink = styled(Link)`
  margin: 0;
  font-size: 10px;
  line-height: 24px;
  color: #999b9e;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

function Footer() {
  const isMobile = useWindowWide(576);

  const onAppStoreClick = () => {
    window.open(
      "https://apps.apple.com/kz/app/dispatch/id6449212365",
      "_blank"
    );
  };

  const onGooglePlayClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.dispatchcds&pli=1",
      "_blank"
    );
  };

  return (
    <StyledFooter>
      <Container>
        <StyledFooterTop>
          {!isMobile && (
            <StyledLogoContainer to="/">
              <IconLogo />
            </StyledLogoContainer>
          )}
          <Grid
            gap={40}
            auto={!isMobile}
            columns={isMobile ? 1 : 4}
            style={{ width: isMobile ? "100%" : "initial" }}
          >
            <StyledIconButton href="tel:+77710460533">
              <StyledIconWrapper>
                <IconPhone24 />
              </StyledIconWrapper>
              <span>+7 (771) 046 05 33</span>
            </StyledIconButton>
            <StyledIconButton href="mailto:cds@dispatchcds.com">
              <StyledIconWrapper>
                <IconMail24 />
              </StyledIconWrapper>
              <span>support@dispatchcds.com</span>
            </StyledIconButton>
            <Grid
              style={{ display: "none" }}
              columns={isMobile ? 4 : 2}
              gap={16}
            >
              <IconInstagram28 />
              <IconTelegram28 />
            </Grid>
            <Grid columns={2} gap={16}>
              <IconAppStore
                onClick={onAppStoreClick}
                style={{ margin: "0 auto" }}
              />
              <IconGooglePlay
                onClick={onGooglePlayClick}
                style={{ margin: "0 auto" }}
              />
            </Grid>
          </Grid>
        </StyledFooterTop>
        <StyledFooterBottom>
          <StyledText>
            Все права на публикуемые на сайте материалы принадлежат компании
            DISPATCH © 2016-2025
          </StyledText>
          {!isMobile && (
            <StyledLinks>
              <StyledLink to="/privacy-policy">
                Политика конфиденциальности
              </StyledLink>
            </StyledLinks>
          )}
        </StyledFooterBottom>
      </Container>
    </StyledFooter>
  );
}

export default memo(Footer);
