import React, {
  createContext,
  Dispatch,
  memo,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AxiosError } from "axios";
import { MarkerClusterer } from "@react-google-maps/api";
import styled from "styled-components";
import { GEOFence, GeoFenceForm, VehicleTrack } from "@app/models";
import { Toggle } from "@app/components";
import {
  APIResponse,
  getVehicleReport,
  getVehicleState,
  GPSGeofencesListRequest,
} from "@app/api";
import { useGoogleMap } from "../GoogleMap";
import {
  dateFormat,
  dateStatisticFormat,
  getAxiosErrorMessage,
  toLatLng,
} from "@app/helpers";
import { TrackPlayer, VehicleStatistics } from "./components";
import VehicleMarker from "../../pages/Monitoring/components/VehicleMarker/VehicleMarker";
import VehicleTrackSrc from "./components/TrackPlayer/vehicle-track.svg";
import MonitoringContextMenu from "../../pages/Monitoring/components/ContextMenu";
import { useNotification } from "../Notification";
import PolyFence from "../../pages/Monitoring/components/PolyFence";

interface Props extends PropsWithChildren {}

interface MonitoringContextValue {
  vehicles: VehicleTrack[];
  setVehicles: (data: VehicleTrack[]) => void;
  setVehicle: Dispatch<SetStateAction<VehicleTrack | null>>;
  controlsHidden: boolean;
  hideControls: Dispatch<SetStateAction<boolean>>;
  // св-ва геозоны
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setGEOFence: (data: GeoFenceForm | null) => void;
  geoFenceDetail: GeoFenceForm | null;
  latLngList: google.maps.LatLng[];
  setGEOFenceLatLngList: Dispatch<SetStateAction<google.maps.LatLng[]>>;
  gpsGEOFences: GPSGeofencesListRequest[];
  setGpsGEOFences: Dispatch<SetStateAction<GPSGeofencesListRequest[]>>;
  selectedGeoFences: GEOFence[];
  setSelectedGeofence: Dispatch<SetStateAction<GEOFence[]>>;
  vehicleReport: any;
  setVehicleReport: Dispatch<SetStateAction<any>>;
}

const MonitoringContext = createContext<MonitoringContextValue>(
  {} as MonitoringContextValue
);

const StyledTrackPlayer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 16px;
  margin-left: -240px;
`;

const StyledVehicleStatistics = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const StyledOnlineToggle = styled.div`
  position: absolute;
  top: 16px;
  left: calc(50% - 90px);
  width: 180px;
`;

function MonitoringProvider(props: Props) {
  const { children } = props;
  const { showNotification } = useNotification();
  const { setZoom, setCenter, map } = useGoogleMap();
  const [controlsHidden, setControlsHidden] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<VehicleTrack[]>([]);
  const [online, setOnline] = useState<boolean>(false);
  const [onlineTrack, setOnlineTrack] = useState<VehicleTrack | null>(null);
  const [vehicle, setVehicle] = useState<VehicleTrack | null>(null);
  const [geoFenceDetail, setGEOFenceDetail] = useState<GeoFenceForm | null>(
    null
  );
  const [geoFenceLatLngList, setGEOFenceLatLngList] = useState<
    google.maps.LatLng[]
  >([]);
  const [gpsGEOFences, setGpsGEOFences] = useState<GPSGeofencesListRequest[]>(
    []
  );
  const [selectedGeoFences, setSelectedGeofence] = useState<GEOFence[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [vehicleReport, setVehicleReport] = useState<any>(null);

  const [vehicleZoom, setVehicleZoom] = useState<number>();

  const stateInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();

    // Логика такая: при изменении данных центровать карту и тд
    if (geoFenceLatLngList.length > 0) {
      // при просмотре из Журнала, надо показать геозону вместе с маршрутом
      if (vehicle && vehicle.points.length) {
        const geofence = new google.maps.Polygon({ paths: geoFenceLatLngList });
        const vehPoint = vehicle.points[0];
        const point = new google.maps.LatLng(
          vehPoint.latitude,
          vehPoint.longitude
        );
        // метод containsLocation проверяет, находится ли первая точка в геозоне
        // если да - добавляем точки в bounds, чтобы все вместе отобразить и все влезло
        if (google.maps.geometry.poly.containsLocation(point, geofence)) {
          geoFenceLatLngList.forEach((point) => bounds.extend(point));
        } else {
          showNotification({
            message: "Техника находится за пределами имеющейся геозоны",
            variant: "warning",
          });
        }
      }

      // если техники нет, то показываем только геозону и вмещаем ее в карту
      if (!vehicle) {
        geoFenceLatLngList.forEach((point) => bounds.extend(point));
        setCenter({
          latitude: bounds.getCenter().lat(),
          longitude: bounds.getCenter().lng(),
        });
        map.fitBounds(bounds);
        setZoom(map.getZoom()!);
        return;
      }
    }

    if (!!vehicle) {
      vehicle.points.forEach((point) => bounds.extend(toLatLng(point)));

      map.fitBounds(bounds);
      setCenter({
        latitude: bounds.getCenter().lat(),
        longitude: bounds.getCenter().lng(),
      });

      setZoom(map.getZoom()!);
      setVehicleZoom(map.getZoom());

      return;
    }

    if (vehicles.length > 1) {
      const sumLat = vehicles
        .filter((point) => !!point.dateTime)
        .reduce((accumulator, marker) => accumulator + marker.latitude, 0);
      const sumLng = vehicles
        .filter((point) => !!point.dateTime)
        .reduce((accumulator, marker) => accumulator + marker.longitude, 0);
      const latitude =
        sumLat / vehicles.filter((point) => !!point.dateTime).length;
      const longitude =
        sumLng / vehicles.filter((point) => !!point.dateTime).length;

      if (
        !!latitude &&
        !!longitude &&
        !vehicleZoom &&
        selectedGeoFences.length === 0
      ) {
        setCenter({
          latitude,
          longitude,
        });

        vehicles
          .filter((point) => !!point.dateTime)
          .forEach((point) => bounds.extend(toLatLng(point)));

        map.fitBounds(bounds);

        setZoom(map.getZoom()!);

        return;
      }
    }
  }, [
    geoFenceLatLngList,
    map,
    setCenter,
    setZoom,
    showNotification,
    vehicle,
    vehicles,
    vehicleZoom,
    selectedGeoFences.length,
  ]);

  // set geoFence detail and path
  const setGEOFence = useCallback((data: GeoFenceForm | null) => {
    setGEOFenceDetail(data);
  }, []);

  const getVehicleStateData = useCallback(
    async (vehicleId: string) => {
      try {
        const { data: vehicleStateData } = await getVehicleState(vehicleId);

        if (!!vehicleStateData) {
          setOnlineTrack((prevOnlineTrack) => ({
            ...prevOnlineTrack!,
            points: [
              ...prevOnlineTrack!.points,
              {
                latitude: vehicleStateData.latitude,
                longitude: vehicleStateData.longitude,
                dateTime: vehicleStateData.dateTime,
                speed: vehicleStateData.speed,
              },
            ],
          }));
        } else {
          setOnlineTrack(null);
          showNotification({
            message: "Нет данных",
            variant: "error",
          });
        }
      } catch (e) {
        console.error(e);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  const onClickMarker = useCallback(
    (item: VehicleTrack) => {
      setVehicle(vehicle?.id === item.id ? null : item);
    },
    [vehicle]
  );

  const onChangeOnline = useCallback(async () => {
    try {
      setOnline((prevOnline) => !prevOnline);
      setOnlineTrack(null);

      if (!!stateInterval.current) {
        clearInterval(stateInterval.current);
      }

      if (online) {
        setVehicle((prevVehicle) => ({
          ...prevVehicle!,
          points: [
            ...(prevVehicle?.points || []),
            ...(onlineTrack?.points || []).splice(1),
          ],
        }));

        return;
      }

      const { data: vehicleStateData } = await getVehicleState(vehicle!.id);

      setOnlineTrack({
        ...vehicle!,
        points: [vehicleStateData],
      });

      stateInterval.current = setInterval(
        () => getVehicleStateData(vehicle!.id),
        60000
      );
    } catch (e) {}
  }, [vehicle, online, getVehicleStateData, onlineTrack]);

  const updateVehicles = useCallback((data: VehicleTrack[]) => {
    setVehicles(data);

    if (data.length === 1) {
      setVehicle(data[0]);
    } else {
      setVehicle(null);
    }
  }, []);

  const fetchVehicleReport = useCallback(
    async (vehicleId: string, dateMin: Date, dateMax: Date) => {
      try {
        const { data: vehicleReportData } = await getVehicleReport(vehicleId, {
          fromDateTime: dateStatisticFormat(dateMin, "yyyy-MM-dd HH:mm"),
          toDateTime: dateStatisticFormat(dateMax, "yyyy-MM-dd HH:mm"),
        });
        setVehicleReport(vehicleReportData);
      } catch (error) {
        console.error("Error fetching vehicle report", error);
      }
    },
    [setVehicleReport]
  );

  // useEffect(() => {
  //   if (vehicle && vehicle.dateMin && vehicle.dateMax) {
  //     const dateMin = formatToLocalString(vehicle.dateMin);
  //     const dateMax = formatToLocalString(vehicle.dateMax);
  //
  //     fetchVehicleReport(vehicle.id, new Date(dateMin), new Date(dateMax));
  //   }
  // }, [vehicle, fetchVehicleReport]);

  useEffect(() => {
    return () => {
      if (stateInterval.current) {
        setOnline(false);
        clearInterval(stateInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      if (!vehicleZoom) {
        setCenter({ latitude, longitude });
      }
    });
  }, [setCenter, vehicleZoom]);

  return (
    <MonitoringContext.Provider
      value={{
        setGEOFence,
        geoFenceDetail,
        vehicles,
        setVehicles: updateVehicles,
        setVehicle,
        controlsHidden,
        hideControls: setControlsHidden,
        modalVisible,
        setModalVisible,
        latLngList: geoFenceLatLngList,
        setGEOFenceLatLngList,
        gpsGEOFences,
        setGpsGEOFences,
        selectedGeoFences,
        setSelectedGeofence,
        vehicleReport,
        setVehicleReport,
      }}
    >
      {((!!vehicle && vehicle.points.length > 0) || !!onlineTrack) && (
        <>
          {!controlsHidden && (
            <StyledOnlineToggle>
              <Toggle
                value={online}
                onClick={onChangeOnline}
                negativeLabel="Оффлайн"
                positiveLabel="Онлайн"
              />
            </StyledOnlineToggle>
          )}
          <StyledTrackPlayer>
            <TrackPlayer
              track={(onlineTrack || vehicle) as VehicleTrack}
              online={online}
            />
          </StyledTrackPlayer>
        </>
      )}
      <StyledVehicleStatistics>
        <VehicleStatistics vehicle={vehicle} />
      </StyledVehicleStatistics>
      {vehicles.length > 1 && (
        <>
          <MarkerClusterer
            options={{
              maxZoom: 15,
              imagePath:
                "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
              averageCenter: true,
            }}
          >
            {(clusterer) =>
              //@ts-ignore
              vehicles
                .filter((itemVehicle) =>
                  !!vehicle && vehicle.points.length > 0
                    ? vehicle.id !== itemVehicle.id
                    : true
                )
                .filter((itemVehicle) => !!itemVehicle.dateTime)
                .map((itemVehicle) => (
                  <VehicleMarker
                    onClick={onClickMarker}
                    key={itemVehicle.id}
                    trackPoint={{
                      ...itemVehicle,
                    }}
                    vehicle={itemVehicle}
                    options={{ icon: VehicleTrackSrc }}
                    clusterer={clusterer}
                    currentDateTime={dateFormat(
                      new Date(itemVehicle.dateTime * 1000),
                      "dd.MM.yyyy HH:mm:ss"
                    )}
                  />
                ))
            }
          </MarkerClusterer>
        </>
      )}
      {!controlsHidden && <MonitoringContextMenu />}
      {selectedGeoFences.map((item) => (
        <PolyFence key={item.id} geoFence={item} />
      ))}
      {children}
    </MonitoringContext.Provider>
  );
}

export function useMonitoring(): MonitoringContextValue {
  return useContext(MonitoringContext);
}

export default memo(MonitoringProvider);
