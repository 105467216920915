import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { slideList } from "../../assets/images/slides";

interface Props extends PropsWithChildren {}

const StyledLayout = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  background: #fff;
`;

const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media only screen and (max-width: 576px) {
    width: 100%;
    background-size: cover;
  }
`;

const ImageBackground = styled.div<{
  bg: string;
  position: string;
}>`
  flex: 1;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: ${(props) => props.position};
  background-repeat: no-repeat;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const LayoutAuth = (props: Props) => {
  const { children } = props;

  const randomImage = slideList[Math.floor(Math.random() * slideList.length)];

  return (
    <StyledLayout>
      <StyledContent>{children}</StyledContent>

      <ImageBackground bg={randomImage.path} position={randomImage.position} />
    </StyledLayout>
  );
};

export default LayoutAuth;
