import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AxiosError } from "axios";
import { Button, Modal, ModalProps, Table, Tabs } from "@app/components";
import { IconChevronDown24, IconChevronUp24 } from "@app/icons";
import {
  getActNomenclatures,
  getActOfWorkInit,
  getActVehicleDetails,
  getAxiosErrorMessage,
  getVehiclesSum,
  isPartner,
} from "@app/helpers";
import {
  ActDetail,
  ActListItem,
  ActOfWork,
  ActStatus,
  ActVehicleItemV2,
  FileObject,
  KIPVehicleDetails,
  SmartContract,
  SmartContractStatus,
  VehicleJournalForActV2,
} from "@app/models";
import {
  APIResponse,
  getActById,
  getActOfProvidedWorkBasisList,
  getProject,
  getVehiclesByActIdV2,
} from "@app/api";
import {
  ModalActOfServicesRevoke,
  ModalActOfWorkDetail,
  ModalContractDocDetail,
  ModalKIPVehicleTrack,
} from "@app/modals";
import { ActApprovalList, downloadAllFiles } from "@app/common";
import { useNotification, useUser } from "@app/providers";
import { actTableLabels, getActVehicleFiles } from "@app/helpers";
import * as Styled from "./components/styled";
import {
  AOSDetailHeader,
  AOSModalTitle,
  getMapTableData,
  mapTableCollapsedData,
} from "./components";

interface Props extends Omit<ModalProps, "title"> {
  data: ActListItem | null;
}

function ModalActOfServicesDetail(props: Props) {
  const { onClose, data, ...restProps } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(true);
  const [act, setAct] = useState<ActDetail | null>(null);
  const [actVehicles, setActVehicles] = useState<VehicleJournalForActV2[]>([]);
  const [collapsedRows, setCollapsedRows] = useState<number[]>([]);
  const [actOfWork, setActOfWork] = useState<ActOfWork | null>(null);
  const [contractId, setContractId] = useState<SmartContract["id"] | null>(
    null
  );
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [selectedAct, setSelectedAct] = useState<ActVehicleItemV2>();
  const [vehicleDetails, setVehicleDetails] =
    useState<KIPVehicleDetails | null>(null);
  const [fileLoading, setFileLoading] = useState(false);

  const isActAgreed = useMemo(
    () =>
      act &&
      [
        ActStatus.Agreed,
        ActStatus.ToBeAgreed,
        ActStatus.AvrRevoked,
        ActStatus.ActSigningRejected,
      ].indexOf(act.status) > -1,
    [act]
  );

  const isContractSigned = useMemo(
    () => data?.contract.status === SmartContractStatus.Signed,
    [data?.contract.status]
  );

  const isActNotAgreed = useMemo(
    () => data?.status !== ActStatus.Agreed,
    [data?.status]
  );

  const canCreateAVR = useMemo(() => {
    if (!data?.status || pending || !isContractSigned) {
      return false;
    }

    return [
      ActStatus.Agreed,
      ActStatus.ActSigningRejected,
      ActStatus.AvrRevoked,
    ].includes(data.status);
  }, [data?.status, isContractSigned, pending]);

  const onClickCreateAvr = useCallback(async () => {
    try {
      if (!act) {
        return;
      }

      setPending(true);

      const { data: vehiclesByAct } = await getVehiclesByActIdV2(act.id, true);
      const { data: basisListData } = await getActOfProvidedWorkBasisList();

      const actOfWorkInit = getActOfWorkInit(act);

      setActOfWork({
        ...actOfWorkInit,
        basis: basisListData.find((item) => item.isActBasis) || null,
        nomenclatures: getActNomenclatures(vehiclesByAct, act.endWorkDate),
      });

      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [act]);

  const onCloseActOfWorkModal = useCallback(() => {
    setActOfWork(null);
  }, []);

  const onContractDetailClose = useCallback(() => {
    setContractId(null);
  }, []);

  const showContractDetail = useCallback(async () => {
    if (!data?.contract.contractDraftId) {
      return;
    }

    setContractId(data.contract.contractDraftId);
  }, [data?.contract.contractDraftId]);

  /*const hidePrintButton = useMemo(() => {
    return (
      userIsPartner &&
      data?.status &&
      [
        ActStatus.Created,
        ActStatus.ToBeAgreed,
        ActStatus.Rejected,
        ActStatus.ActSigningRejected,
        ActStatus.ActSigningOverdue,
        ActStatus.Deleted,
        ActStatus.AvrRevoked,
        ActStatus.AvrDeleted,
      ].indexOf(data.status) > -1
    );
  }, [data, userIsPartner]);*/

  const modalTitle = useMemo(() => {
    if (!data) {
      return null;
    }

    if (pending) {
      return "Загружается...";
    }

    return (
      <AOSModalTitle
        data={data}
        avrId={act?.avrId}
        isActNotAgreed={isActNotAgreed}
        isContractSigned={isContractSigned}
        canCreateAVR={canCreateAVR}
        pending={pending}
        onAvrCreate={onClickCreateAvr}
      />
    );
  }, [
    act?.avrId,
    canCreateAVR,
    data,
    isActNotAgreed,
    isContractSigned,
    onClickCreateAvr,
    pending,
  ]);

  const getActData = useCallback(
    async (id: ActDetail["id"]) => {
      try {
        const { data: actData } = await getActById(id);
        setPending(false);
        setAct(actData);
        if (actData.actVehicleGroupDto) {
          // проверяем проект на необходимость расшифровки табеля по дням
          let vehicleDetailList: ActVehicleItemV2[] | null = [];
          const { data: currentProject } = await getProject(actData.project.id);
          // добавляем в список техники, данные из vehicleDetails (журналы по дням месяца)
          if (currentProject.isActVehicleDetailsRequired) {
            vehicleDetailList = actData.vehicleDetails;
          }

          const vehicles = getActVehicleDetails(
            actData.actVehicleGroupDto,
            vehicleDetailList
          );
          setActVehicles(vehicles);
        }
      } catch (e) {
        setPending(false);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  const onPlayClick = useCallback((act: ActVehicleItemV2) => {
    setSelectedAct(act);
    setVehicleDetails({
      workStartDateTime: act.startTime,
      workEndDateTime: act.endTime,
    } as KIPVehicleDetails);
  }, []);

  const onCloseTrackModal = useCallback(() => {
    setVehicleDetails(null);
  }, []);

  const onRevokeModalClose = useCallback(
    (refresh?: boolean) => {
      if (refresh && data?.id) {
        getActData(data.id);
      }
      setShowRevokeModal(false);
    },
    [data?.id, getActData]
  );

  const onFilesDownload = useCallback(
    (files: FileObject[]) => async () => {
      try {
        setFileLoading(true);
        await downloadAllFiles(files);
        setFileLoading(false);
      } catch (e) {
        setFileLoading(false);
        showNotification({
          message: "Не удалось скачать все файлы",
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  useEffect(() => {
    if (data?.id) {
      getActData(data.id);
    }
  }, [data?.id, getActData]);

  const reduceTableData = useCallback(
    (item: VehicleJournalForActV2) => {
      return item?.items ? getMapTableData(item.items, onPlayClick) : [];
    },
    [onPlayClick]
  );

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => actVehicles.map(mapTableCollapsedData),
    [actVehicles]
  );

  const tableDataChildren = useMemo<(string | ReactNode)[][][]>(
    () => actVehicles.map(reduceTableData),
    [actVehicles, reduceTableData]
  );

  const onParentRowExpand = useCallback(() => {
    if (collapsedRows.length) {
      setCollapsedRows([]);
    } else {
      setCollapsedRows(tableData.map((_, i) => i));
    }
  }, [collapsedRows.length, tableData]);

  const tableExtraRow = useMemo<(string | ReactNode)[]>(
    () =>
      actTableLabels.map((_, index) => {
        if (index === 0) {
          return (
            <Styled.Button onClick={onParentRowExpand}>
              {collapsedRows.length ? (
                <IconChevronDown24 />
              ) : (
                <IconChevronUp24 />
              )}
            </Styled.Button>
          );
        } else if (index === 1) {
          return "Итого";
        } else if (index === 15 || index === 17) {
          return getVehiclesSum(actVehicles, "summa", act?.nds.rate);
        } else if (index === 16) {
          return getVehiclesSum(actVehicles, "summaNds", act?.nds.rate);
        } else if (index === 19) {
          const collectFiles = getActVehicleFiles(actVehicles);
          return collectFiles.length ? (
            <Button
              variant={"contained"}
              text={"Скачать все"}
              size={"small"}
              showLoader={fileLoading}
              onClick={onFilesDownload(collectFiles)}
            />
          ) : null;
        }
        return "";
      }),
    [
      act?.nds.rate,
      actVehicles,
      collapsedRows.length,
      fileLoading,
      onFilesDownload,
      onParentRowExpand,
    ]
  );

  const tabLabels = useMemo<string[]>(() => {
    const tabs = ["Детализация работ"];
    // Скрыть для партнера 'Лист согласования'
    return isPartner(user!.role) ? tabs : [...tabs, "Лист согласования"];
  }, [user]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];

    if (isActAgreed) {
      returnActions.push(
        <Button
          text="Отозвать"
          variant={"outlined"}
          onClick={() => {
            setShowRevokeModal(true);
          }}
        />
      );
    }
    return returnActions;
  }, [isActAgreed]);

  return (
    <>
      <Modal
        title={modalTitle}
        actions={actions}
        dense
        onClose={onClose}
        {...restProps}
      >
        <AOSDetailHeader act={act} onContractClick={showContractDetail} />
        <Styled.Container>
          <Tabs labels={tabLabels}>
            <Styled.TableContainer>
              {tableData.length ? (
                <Table
                  labels={actTableLabels}
                  collapsedData={tableData}
                  nodeData={tableDataChildren}
                  extraRow={tableExtraRow}
                  collapsedRows={collapsedRows}
                  disableOverflow
                  onClick={() => {}}
                  onRowCollapse={(val) => setCollapsedRows(val)}
                />
              ) : (
                <p>Список техник пуст</p>
              )}
            </Styled.TableContainer>
            <Styled.TableContainer>
              <ActApprovalList actId={act?.id} />
            </Styled.TableContainer>
          </Tabs>
        </Styled.Container>
      </Modal>
      <ModalActOfWorkDetail
        actOfWork={actOfWork}
        onClose={onCloseActOfWorkModal}
        open={!!actOfWork}
        isActOfService={true}
      />
      <ModalContractDocDetail
        open={!!contractId}
        contractId={contractId}
        size={"medium"}
        onClose={onContractDetailClose}
      />
      <ModalActOfServicesRevoke
        open={showRevokeModal}
        title="Отозвать табель"
        actId={data?.id}
        onClose={onRevokeModalClose}
      />
      {selectedAct && (
        <ModalKIPVehicleTrack
          open={!!vehicleDetails}
          projectId={act?.project.id}
          vehicleDetails={vehicleDetails}
          vehicleId={selectedAct.vehicleId}
          vehicleGovNumber={selectedAct.govNumber}
          onClose={onCloseTrackModal}
        />
      )}
      {/*<ModalSmartContractDetail
        open={contractDetailVisible}
        data={contract as SmartContract}
        onClose={onContractDetailClose}
      />*/}
    </>
  );
}

export default memo(ModalActOfServicesDetail);
