import React, {
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import checkMark from "./check-mark.svg";

interface Props {
  label?: string | ReactNode;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
  editable?: boolean;
  type?: "checkbox" | "radio";
  fontSize?: string;
}

const StyledCheckbox = styled.div``;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

const StyledLabelText = styled.span<{ fontSize?: string }>`
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  font-size: ${(props) => props.fontSize};
  line-height: 20px;

  a {
    color: ${theme("color.blue")};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledInput = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  background: ${theme("color.white")};
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  cursor: pointer;

  &[type="checkbox"] {
    &:checked {
      background: linear-gradient(86.19deg, #ed4a3f 19.16%, #f99335 78.75%);
      position: relative;

      &::before {
        content: "";
        background-image: url(${checkMark});
        background-repeat: no-repeat;
        position: absolute;
        left: 2px;
        right: 2px;
        top: 3px;
        bottom: 3px;
      }
    }
  }

  &[type="radio"] {
    border-radius: 50%;

    &:checked {
      background: linear-gradient(86.19deg, #ed4a3f 19.16%, #f99335 78.75%);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: calc(50% - 5px);
        top: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${theme("color.white")};
      }
    }
  }
`;

function Checkbox(props: Props) {
  const {
    defaultChecked,
    checked,
    onChange,
    label,
    className = "",
    disabled,
    editable = true,
    type = "checkbox",
    fontSize = "14px",
  } = props;

  const onClickLabel = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>) => {
      if (!editable) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [editable]
  );

  const onChangeCheckbox = useCallback(() => {}, []);

  const inputProps = useMemo(() => {
    const returnProps: InputHTMLAttributes<HTMLInputElement> = {};

    if (typeof checked !== "undefined") {
      returnProps.checked = checked;

      returnProps.onChange = onChange || onChangeCheckbox;
    }

    if (typeof defaultChecked !== "undefined") {
      returnProps.defaultChecked = defaultChecked;
    }

    return returnProps;
  }, [checked, defaultChecked, onChange, onChangeCheckbox]);

  return (
    <StyledCheckbox className={className}>
      <StyledLabel onClick={onClickLabel}>
        {/* FIX: если 2 строки в лейбле, чекбокс становится уже */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <StyledInput type={type} onChange={onChange} {...inputProps} />
        </div>
        {!!label && (
          <StyledLabelText fontSize={fontSize}>{label}</StyledLabelText>
        )}
      </StyledLabel>
    </StyledCheckbox>
  );
}

export default Checkbox;
