import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Modal,
  ModalProps,
  ModalTitle,
  useForm,
} from "@app/components";
import {
  ActDetail,
  ActCreateDTO,
  NdsShort,
  VehicleJournalForActV2,
  ActVehicleItemV2,
  Unit,
} from "@app/models";
import { eachDayOfInterval, format } from "date-fns";
import {
  HeaderInfo,
  StepperRow,
  StepView1,
  StepView2,
  StepView3,
} from "./components";
import {
  APIResponse,
  createAct,
  editAct,
  getActById,
  getProject,
  getVehicleJournalForActV2,
  sendActToApprove,
  setActNotificationRecipients,
  setActVehicleDetails,
} from "@app/api";
import {
  dateFormat,
  getAxiosErrorMessage,
  getVehicleItemsTotal,
  MONTH_UNIT_ID,
} from "@app/helpers";
import { useNotification } from "@app/providers";
import { IconPrint16 } from "@app/icons";
import { AxiosError } from "axios";
import { FilterParams, Signer } from "./types";
import { initFilterValues, schema } from "./constants";
import {
  convertActSignersForSelect,
  getAosCreateFilterParams,
  getAosCreateRequest,
  prepareVehicles,
} from "./helpers";

interface Props extends Omit<ModalProps, "title"> {
  data: ActDetail | null;
  onClose: () => void;
  updateData?: (act: ActDetail | null) => void;
}

function ModalActOfServicesCreate(props: Props) {
  const { onClose, open, data, updateData, ...restProps } = props;

  const { showNotification } = useNotification();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [actVehicles, setActVehicles] = useState<VehicleJournalForActV2[]>([]);
  const [actSigners, setActSigners] = useState<Signer[]>([]);
  const [selectedRecipientIds, setSelectedRecipientIds] = useState<string[]>(
    []
  );
  const [isParallel, setParallel] = useState<boolean>(
    data?.actSignersDto?.every((signer) => signer.queue === 1) ?? false
  );
  const [isActVehicleDetailsRequired, setIsActVehicleDetailsRequired] =
    useState(false);

  const {
    pending,
    onChange,
    values: filterParams,
    errors,
    validate,
    setPending,
    setValues,
    resetForm,
  } = useForm<FilterParams>({
    values: initFilterValues,
    schema,
  });

  const onPrintButtonClick = useCallback(() => {
    if (!data) {
      return;
    }
    window.open(
      `./act-of-provided-services/printing/${data.id}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, [data]);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!data) {
      return "Создание табеля оказанных услуг";
    }

    if (pending) {
      return "Загружается...";
    }

    const createdDate = format(new Date(data.createdDate), "dd.MM.yyyy");

    return (
      <ModalTitle
        text={`Табель оказанных услуг №${data.actNumber} от ${createdDate}`}
      >
        {!!data.status && (
          <Button
            text={"Печатная форма"}
            size={"small"}
            variant={"outlined"}
            startIcon={IconPrint16}
            onClick={onPrintButtonClick}
          />
        )}
      </ModalTitle>
    );
  }, [data, onPrintButtonClick, pending]);

  const reformatActVehicles = useCallback(
    (items: ActVehicleItemV2[] | null, unit: Unit) => {
      // Если ед.изм. Месяц, то нужно добавить построчную расшифровку
      // по дням за период из filterParams.
      if (
        items?.length &&
        unit.id === MONTH_UNIT_ID &&
        filterParams?.startDate &&
        filterParams?.endDate
      ) {
        // Дублируется первая запись в журнале
        const [actVehicle] = items;
        const durationDays = eachDayOfInterval({
          start: filterParams.startDate,
          end: filterParams.endDate,
        });
        // убираем дублирующую дату
        const filtered = durationDays.filter((day) => {
          return day.getDate() !== new Date(actVehicle.workDate).getDate();
        });
        // И изменяется дата, обнуляются суммы т.к. не идут в расчет
        const itemsByDay = filtered.map((workDate) => ({
          ...actVehicle,
          id: "",
          toPay: 0,
          price: 0,
          summa: 0,
          summaNds: 0,
          workDate: dateFormat(workDate, "yyyy-MM-dd"),
          // comment: "Расшифровка по дням",
          unitDto: {
            ...actVehicle.unitDto,
            name: "месяц (день)",
          },
        }));
        return [actVehicle, ...itemsByDay];
      }
      return items;
    },
    [filterParams.endDate, filterParams.startDate]
  );

  const getVehicleList = useCallback(
    (data: VehicleJournalForActV2[]): VehicleJournalForActV2[] => {
      return data.map((vehicle) => ({
        ...vehicle,
        items: reformatActVehicles(vehicle.items, vehicle.unitDto),
      }));
    },
    [reformatActVehicles]
  );

  const getActVehicles = useCallback(async () => {
    if (!filterParams) {
      // show error or validate
      return;
    }

    if (actVehicles.length === 0) {
      const requestParams = getAosCreateRequest(filterParams);
      const response = await getVehicleJournalForActV2(requestParams);

      // проверяем проект на необходимость расшифровки табеля по дням
      const { data: currentProject } = await getProject(
        filterParams.project?.value
      );

      let vehicles = response.data;
      if (currentProject.isActVehicleDetailsRequired) {
        setIsActVehicleDetailsRequired(true);
        vehicles = getVehicleList(response.data);
      }
      setActVehicles(vehicles);
    }
  }, [actVehicles.length, filterParams, getVehicleList]);

  const getCalculateTotal = useCallback(
    async (actDetail: ActDetail, ndsRate: NdsShort["rate"]) => {
      // проверяем проект на необходимость расшифровки табеля по дням
      const { data: currentProject } = await getProject(actDetail.project.id);

      return actDetail.actVehicleGroupDto!.map((parent) => {
        const items = getVehicleItemsTotal(parent.items, ndsRate);
        const [vehicleItem] = parent.items ?? [];
        if (vehicleItem && currentProject.isActVehicleDetailsRequired) {
          const details: ActVehicleItemV2[] =
            actDetail.vehicleDetails?.filter(
              (item) => item.vehicleId === vehicleItem.vehicleId
            ) ?? [];
          const sorted = details.sort(
            (a, b) =>
              new Date(a.workDate).getTime() - new Date(b.workDate).getTime()
          );
          // Убираем id, чтобы далее не сохранить повторно. Отфильтровывается по айди при сохранении
          const formatted = sorted.map((item) => ({
            ...item,
            id: "",
            price: 0,
          }));
          items.push(...formatted);
        }
        return {
          ...parent,
          items,
        };
      });
    },
    []
  );

  const onListChange = (data: VehicleJournalForActV2[], reformat = false) => {
    if (reformat && isActVehicleDetailsRequired) {
      const vehicles = getVehicleList(data);
      setActVehicles(vehicles);
    } else {
      setActVehicles(data);
    }
  };

  const getActDetails = useCallback(async () => {
    try {
      if (!data?.id || !data?.status) {
        return;
      }
      setPending(true);
      const { data: actDetail } = await getActById(data.id);

      setPending(false);

      // ЗАПОЛНИТЬ ПАРАМЕТРЫ ФИЛЬТРА
      const params = getAosCreateFilterParams(actDetail);
      setValues(params);

      // СПИСОК ПОДПИСАНТОВ
      if (actDetail.actSignersDto?.length) {
        const _actSigners = convertActSignersForSelect(actDetail.actSignersDto);
        const sorted = _actSigners.sort((a, b) => a.queue - b.queue);
        setActSigners(sorted);
      }

      // СПИСОК ТЕХНИКИ
      if (actDetail.actVehicleGroupDto?.length) {
        const calculateTotal = await getCalculateTotal(
          actDetail,
          actDetail.nds.rate
        );
        setActVehicles(calculateTotal);
      }
    } catch (e) {
      setPending(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [data, getCalculateTotal, setPending, setValues, showNotification]);

  useEffect(() => {
    if (open) {
      getActDetails(); // Загрузить детали акта, если данные существуют
    }
  }, [getActDetails, open]);

  const onModalClose = useCallback(() => {
    resetForm();
    onClose();
    setCurrentStep(1);

    resetForm(); // Сбросить форму при открытии модального окна
    setActVehicles([]); // Сбросить список техники
    setActSigners([]); // Сбросить список подписантов
    setSelectedRecipientIds([]); // Сбросить список выбранных лиц для уведомлений
  }, [onClose, resetForm]);

  const notifyRecipients = useCallback(
    async (actId: string) => {
      try {
        const res = await setActNotificationRecipients({
          id: actId,
          userIds: selectedRecipientIds,
        });
        if (res.succeeded) {
          showNotification({
            message: "Лица для ознакомления сохранены",
            variant: "success",
          });
        }
      } catch (e) {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [selectedRecipientIds, showNotification]
  );

  const getDetails = useCallback(
    (items: ActVehicleItemV2[]) => {
      // только добавленные строки (без id)
      const filtered = items.filter((item) => !item.id);
      const workDataArray = filtered.map((vehicleItem) => ({
        // @ts-ignore
        shiftTypeId: vehicleItem.shiftTypeDto?.id ?? vehicleItem.shiftType?.id,
        workDate: vehicleItem.workDate,
        workPerformed: vehicleItem.workPerformed ?? "",
        shiftDuration: vehicleItem.shiftDuration ?? "0",
        hoursGps: vehicleItem.hoursGps ?? "0",
      }));
      const isFilled = workDataArray.some((item) => !item.workPerformed);
      if (isFilled) {
        showNotification({
          message: "Не заполнены данные линии",
          variant: "error",
        });
      }
      return workDataArray;
    },
    [showNotification]
  );

  const saveDetails = useCallback(
    async (actId: string) => {
      try {
        const filtered = actVehicles.filter((vehicle) => {
          return vehicle.unitDto.id === MONTH_UNIT_ID && vehicle.items?.length;
        });
        if (filtered.length) {
          const vehicles = filtered.map((vehicleJournal) => {
            return {
              vehicleId: vehicleJournal.items![0].vehicleId,
              details: getDetails(vehicleJournal.items!),
            };
          }, []);

          await setActVehicleDetails({
            id: actId,
            vehicles,
          });
        }
      } catch (e) {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [actVehicles, getDetails, showNotification]
  );

  const onSaveClick = useCallback(
    async (isSaving = true) => {
      const isValid = await validate();

      if (!isValid) {
        return;
      }
      setPending(true);

      const body: ActCreateDTO = {
        companyId: filterParams.organization!.value,
        projectId: filterParams.project!.value,
        partnerId: filterParams.partner!.value,
        contractId: filterParams.contract!.value,
        ndsId: filterParams.nds!.value,
        vehicles: prepareVehicles(actVehicles),
        isLifting: filterParams.vehicleType?.value === "true",
        startWorkDate: dateFormat(filterParams.startDate, "yyyy-MM-dd"),
        endWorkDate: dateFormat(filterParams.endDate, "yyyy-MM-dd"),
        actSigners: actSigners.map((item, actSignerIndex) => ({
          taskType: +item.task!.value,
          userId: item.user!.value,
          queue: isParallel ? 1 : actSignerIndex + 1,
        })),
      };

      let response;
      let message;
      let actId = data?.id;

      try {
        if (isSaving) {
          if (data && data.id) {
            body.id = data.id;
            response = await editAct(body);
            message = "Заявка на технику сохранена";
          } else {
            response = await createAct(body);
            actId = response.data.id;
            message = "Заявка на технику добавлена";
            setValues(initFilterValues);
            if (currentStep === 3) {
              onModalClose();
            }
          }

          if (updateData) {
            updateData(response.data);
          }
        } else {
          body.id = data?.id;
          response = await sendActToApprove(body);
          message = "Заявка на технику отправлена на согласование";
        }

        setPending(false);

        if (!response.succeeded) {
          showNotification({
            message: response.message,
            variant: "error",
          });

          return;
        }

        showNotification({
          message,
          variant: "success",
        });

        const isSameIds =
          selectedRecipientIds.length ===
            data?.actNotificationRecipientsDto?.length &&
          data?.actNotificationRecipientsDto?.every((user) =>
            selectedRecipientIds.includes(user.id)
          );

        if (actId && selectedRecipientIds.length && !isSameIds) {
          await notifyRecipients(actId);
        }

        if (actId) {
          await saveDetails(actId);
        }

        if (!isSaving) {
          onClose();
        }
      } catch (e) {
        setPending(false);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [
      actSigners,
      actVehicles,
      currentStep,
      data,
      filterParams.contract,
      filterParams.endDate,
      filterParams.nds,
      filterParams.organization,
      filterParams.partner,
      filterParams.project,
      filterParams.startDate,
      filterParams.vehicleType?.value,
      isParallel,
      notifyRecipients,
      onClose,
      onModalClose,
      saveDetails,
      selectedRecipientIds,
      setPending,
      setValues,
      showNotification,
      updateData,
      validate,
    ]
  );

  const onPrevClick = useCallback(() => {
    if (currentStep <= 1) {
      return;
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const onNextClick = useCallback(async () => {
    if (currentStep === 1) {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      await getActVehicles();
      setCurrentStep(2);
    } else if (currentStep === 2) {
      if (actVehicles.length === 0) {
        showNotification({
          message: "Техника отсутствует",
          variant: "error",
        });
        return;
      }
      setCurrentStep(3);
    } else if (currentStep === 3) {
      if (actSigners.some((signer) => !signer.user || !signer.task)) {
        showNotification({
          message: "Заполните данные лиц согласования",
          variant: "error",
        });
        return;
      }
      await onSaveClick(false);
    }
  }, [
    actSigners,
    actVehicles.length,
    currentStep,
    getActVehicles,
    onSaveClick,
    showNotification,
    validate,
  ]);

  const onSignersChange = useCallback((data: Signer[]) => {
    setActSigners(data);
  }, []);

  const onRecipientsChange = useCallback((userIds: string[]) => {
    setSelectedRecipientIds(userIds);
  }, []);

  const onApprovalTypeChange = useCallback((isParallel: boolean) => {
    setParallel(isParallel);
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Modal
      title={modalTitle}
      onClose={onModalClose}
      open={open}
      dense
      {...restProps}
    >
      {currentStep > 1 && <HeaderInfo filterParams={filterParams} />}
      <StepperRow
        currentStep={currentStep}
        loading={pending}
        approveDisabled={currentStep === 3 && actSigners.length === 0}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        onSaveClick={onSaveClick}
      />
      {currentStep === 1 && (
        <StepView1
          actData={data}
          filterParams={filterParams}
          filterErrors={errors}
          onFilterChange={onChange}
        />
      )}
      {currentStep === 2 && (
        <StepView2
          actData={data}
          actVehicles={actVehicles}
          filterParams={filterParams}
          onListChange={onListChange}
        />
      )}
      {currentStep === 3 && (
        <StepView3
          actData={data}
          actSigners={actSigners}
          selectedRecipientIds={selectedRecipientIds}
          isParallel={isParallel}
          onSignersChange={onSignersChange}
          onRecipientsChange={onRecipientsChange}
          onApprovalTypeChange={onApprovalTypeChange}
        />
      )}
      {/*{open && <FormActOfServices actData={data} onSuccess={onSuccess} />}*/}
    </Modal>
  );
}

export default memo(ModalActOfServicesCreate);
