import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ActHistoryItemDTO } from "@app/models";
import { Table } from "@app/components";
import { getActHistory } from "@app/api";
import { dateFormatWithTimezone, getFullName } from "@app/helpers";
import styled from "styled-components";

type Props = {
  actId?: string;
  printing?: boolean;
};

const StyledTableHead = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 14px 12px;
  text-align: left;
`;

const StyledTitle = styled.h3`
  margin: 24px 0;
`;

const ActHistory = (props: Props) => {
  const { actId, printing = false } = props;

  const [list, setList] = useState<ActHistoryItemDTO[]>([]);

  const tableLabels = useMemo(
    () => ["Дата", "Статус", "ФИО", "Комментарий"],
    []
  );

  const mapTableData = useCallback((item: ActHistoryItemDTO) => {
    return [
      dateFormatWithTimezone(item.created, "dd.MM.yyyy HH:mm:ss"),
      item.text ?? "",
      getFullName(item.user),
      item.comment ?? "",
    ];
  }, []);

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => list.map(mapTableData),
    [list, mapTableData]
  );

  useEffect(() => {
    if (!actId) return;

    getActHistory(actId).then((res) => {
      setList(res.data);
    });
  }, [actId]);

  if (printing) {
    if (list.length === 0) {
      return null;
    }

    return (
      <>
        <h3>История согласования</h3>
        <table>
          <thead>
            <tr>
              {tableLabels.map((label, labelIndex) => (
                <StyledTableHead key={labelIndex.toString()}>
                  {label}
                </StyledTableHead>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index.toString()}>
                {tableLabels.map((_, labelIndex) => (
                  <td>{tableData[index][labelIndex]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  return (
    <div>
      <StyledTitle>История согласования</StyledTitle>
      <Table
        labels={tableLabels}
        data={tableData}
        disableOverflow={true}
        onClick={() => {}}
      />
    </div>
  );
};

export default memo(ActHistory);
