import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { Button, Grid, Modal, ModalProps, TextField } from "@app/components";
import { useNotification } from "@app/providers";
import { approveActTask } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { ConstructiveContext } from "@app/common";
import { ConstructiveRequest } from "@app/models";

interface Props extends ModalProps {
  taskId?: string;
  actId?: string;
  onClose: (val?: boolean) => void;
}

const ModalActTaskApprove = (props: Props) => {
  const { onClose, taskId, actId, ...restProps } = props;
  const { showNotification } = useNotification();
  const { requestList } = useContext(ConstructiveContext);

  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const onApproveConfirm = useCallback(async () => {
    if (!taskId || !actId) {
      return;
    }

    // Зачем это вызывать?
    // try {
    //   const response = await getActById(actId);
    //   const { actConstructiveDto } = response.data;
    //   // setActConstructives(actConstructiveDto);
    // } catch (e) {
    //   showNotification({
    //     message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
    //     variant: "error",
    //   });
    // }

    const constructives: ConstructiveRequest[] = requestList.map((item) => ({
      constructiveId: item.constructive?.value!,
      projectSite: item.projectSite?.label,
      sum: item.sum!,
    }));

    setLoading(true);
    approveActTask({ id: taskId, comment, constructives })
      .then(() => {
        showNotification({
          variant: "success",
          message: "Согласован",
        });
        onClose(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e),
          variant: "error",
        });
      });
  }, [actId, comment, onClose, requestList, showNotification, taskId]);

  const actions = useMemo(
    () => [
      <Button
        text="Подтвердить согласование"
        onClick={onApproveConfirm}
        showLoader={loading}
      />,
    ],
    [loading, onApproveConfirm]
  );

  const onCommentChange = useCallback((value: string) => {
    setComment(value);
  }, []);

  return (
    <Modal actions={actions} onClose={onClose} {...restProps}>
      <Grid>
        <TextField
          label="Комментарий"
          placeholder="Добавьте комментарий (необязательно)"
          value={comment}
          name="comment"
          onChange={onCommentChange}
        />
      </Grid>
    </Modal>
  );
};

export default memo(ModalActTaskApprove);
