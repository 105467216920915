import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Company,
  Confirmer,
  ConstructiveShort,
  DocumentType,
  Project,
  ProjectSite,
  Task,
  UserTaskStatuses,
  UserTaskStatusesName,
} from "@app/models";
import {
  companyLabelKeys,
  dateFormat,
  enumToArray,
  getDateFilterValue,
  getFilterValues,
  getFullName,
  isDispatcher,
} from "@app/helpers";
import { TemplateSearch } from "@app/templates";
import {
  getCompanies,
  getCompanyEmployeesShort,
  getDispatchers,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getTasks,
  PageableParams,
} from "@app/api";
import {
  ModalActTaskApprove,
  ModalActTaskDetail,
  ModalActTaskRedirect,
  ModalActTaskReject,
} from "@app/modals";
import {
  Checkbox,
  Combobox,
  DateTimePicker,
  DefaultObject,
  SelectOption,
} from "@app/components";
import { useDebounce } from "@app/hooks";
import { useNotification, useUser } from "@app/providers";

const FILTER_STORAGE_KEY = "actTasksFilters";

interface Props {
  documentType: DocumentType;
}

interface CustomUser extends Confirmer {
  name: string;
}

export interface CustomConstructive extends ConstructiveShort {
  name: string;
}

export interface CustomProjectSite extends ProjectSite {
  name: string;
  isNewProjectSite: boolean;
}

export interface ConstructiveReq {
  constructive?: SelectOption<CustomConstructive> | null;
  projectSite?: SelectOption<CustomProjectSite> | null;
  sum?: number;
}

interface FilterParamsProps {
  customer: Array<Company> | null;
  initiator: Array<CustomUser> | null;
  executor: Array<Company> | null;
  executorUser: Array<CustomUser> | null;
  project: Array<Project> | null;
  status: DefaultObject<UserTaskStatuses>[] | null;
  startDate: Date | null;
  endDate: Date | null;
}

interface FilterValuesProps {
  CustomerIds: string[];
  InitiatorIds: string[];
  ExecutorIds: string[];
  ExecutorUserIds: string[];
  ProjectIds: string[];
  TaskStatusIds: (string | number)[];
  FromDate?: string;
  ToDate?: string;
}

const initialFiltersParams = JSON.stringify({
  initiator: null,
  customer: null,
  executor: null,
  executorUser: null,
  project: null,
  endDate: null,
  startDate: null,
  status: null,
});

type ContextType = {
  requestList: ConstructiveReq[];
  setRequestList: React.Dispatch<React.SetStateAction<ConstructiveReq[]>>;
};
export const ConstructiveContext = createContext<ContextType>(
  {} as ContextType
);

function ActTasks({ documentType }: Props) {
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [redirectModalVisible, setRedirectModalVisible] =
    useState<boolean>(false);
  const [taskSelected, setTaskSelected] = useState<Task | null>(null);
  const [showRejecting, setShowRejecting] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [onlyUnfinished, setOnlyUnfinished] = useState(true);
  const [initiators, setInitiators] = useState<CustomUser[]>([]);
  const [executors, setExecutors] = useState<CustomUser[]>([]);
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });
  const [requestList, setRequestList] = useState<ConstructiveReq[]>([]);

  const tableLabels = useMemo(
    () => [
      "Наименование задачи",
      "Заказчик",
      "Проект",
      "Исполнитель/Контрагент",
      "Дата назначения",
      "Дата исполнения",
      "Статус задачи",
      "Исполнитель задачи",
      "Инициатор",
    ],
    []
  );

  const mapTableData = useCallback((item: Task) => {
    return [
      item.name,
      item.customer?.name || "-",
      item.project.name,
      item.executor.name,
      dateFormat(item.dateStart, "dd.MM.yyyy"),
      item.dateFinish ? dateFormat(item.dateFinish, "dd.MM.yyyy") : "-",
      UserTaskStatusesName[item.status],
      getFullName(item.executorUser),
      getFullName(item.initiator),
    ];
  }, []);

  const leftControls = useMemo(
    () => [
      <Checkbox
        label={"Только незакрытые задачи"}
        checked={onlyUnfinished}
        onChange={() => {
          setOnlyUnfinished((v) => !v);
        }}
      />,
    ],
    [onlyUnfinished]
  );

  const onClickItem = useCallback((data: Task) => {
    setTaskSelected(data);
    setModalVisible(true);
  }, []);

  const onApproveModalShow = useCallback(() => {
    const isValid = requestList.every(
      (item) => !!item.constructive && item.sum && item.sum > 0
    );
    const rowNotAdded = requestList.some(
      (item) => !item.constructive && item.sum === 0
    );

    let message = "Проверьте конструктивы, необходимо заполнить все поля";
    if (rowNotAdded) {
      message = "Проверьте конструктивы, возможно не была добавлена строка";
    }
    if (!isValid) {
      showNotification({
        message,
        variant: "error",
      });
      return;
    }
    setShowApproveModal(true);
  }, [requestList, showNotification]);

  const onModalClose = useCallback(() => {
    setTaskSelected(null);
    setModalVisible(false);
  }, []);

  const getTaskList = useCallback(
    (params: PageableParams) => {
      return getTasks({
        ...params,
        forModuleId: documentType,
        isUnfinished: onlyUnfinished,
      });
    },
    [documentType, onlyUnfinished]
  );

  const closeAllModals = useCallback((closeDetail?: boolean) => {
    setRedirectModalVisible(false);
    setShowApproveModal(false);
    setShowRejecting(false);

    if (closeDetail) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
      setModalVisible(false);
    }
  }, []);

  useEffect(() => {
    getDispatchers().then(({ data }) => {
      const arr = data.map((user) => ({
        name: getFullName(user),
        ...user,
      }));
      setInitiators(arr);
    });
  }, []);

  useEffect(() => {
    const execIds = getFilterValues(filterParams.executor);
    const custIds = getFilterValues(filterParams.customer);

    if (execIds.length || custIds.length) {
      getCompanyEmployeesShort([...execIds, ...custIds]).then(({ data }) => {
        const arr = data.map(({ user }) => ({
          name: getFullName(user),
          ...user,
        }));

        setExecutors(arr);
      });
    } else {
      setExecutors([]);
      return;
    }
  }, [filterParams.customer, filterParams.executor]);

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        InitiatorIds: getFilterValues(filterParams.initiator),
        ExecutorIds: getFilterValues(filterParams.executor),
        ExecutorUserIds: getFilterValues(filterParams.executorUser),
        CustomerIds: getFilterValues(filterParams.customer),
        ProjectIds: getFilterValues(filterParams.project),
        TaskStatusIds: getFilterValues(filterParams.status),
        FromDate: getDateFilterValue(filterParams.startDate),
        ToDate: getDateFilterValue(filterParams.endDate),
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox<Company>
        label="Заказчик"
        name="customer"
        onChange={onFilterChange}
        values={filterParams.customer}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={getCompanies}
      />,
      <Combobox<CustomUser>
        label="Инициатор"
        name="initiator"
        values={filterParams.initiator}
        options={initiators}
        onChange={onFilterChange}
      />,
      <Combobox<Company>
        label="Контрагент"
        name="executor"
        onChange={onFilterChange}
        values={filterParams.executor}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<CustomUser>
        label="Исполнитель"
        name="executorUser"
        values={filterParams.executorUser}
        options={executors}
        onChange={onFilterChange}
      />,
      <Combobox<Project>
        label="Проект"
        name="project"
        onChange={onFilterChange}
        values={filterParams.project}
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
      />,
      <Combobox<DefaultObject<UserTaskStatuses>>
        label="Статус задачи"
        name="status"
        values={filterParams.status}
        options={enumToArray(UserTaskStatusesName)}
        onChange={onFilterChange}
      />,
      <DateTimePicker
        label="Дата назначения"
        name="startDate"
        onChange={onFilterChange}
        value={filterParams.startDate}
        hideTime
      />,
      <DateTimePicker
        label="Дата завершения"
        name="endDate"
        onChange={onFilterChange}
        value={filterParams.endDate}
        hideTime
      />,
    ],
    [executors, filterParams, initiators, onFilterChange, user]
  );

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <ConstructiveContext.Provider
      value={{
        requestList,
        setRequestList,
      }}
    >
      <React.Fragment>
        <TemplateSearch
          toolbarProps={{
            onFilter: () => {},
            searchPlaceholder: "Задача, инициатор проект, контрагент",
            leftControls,
          }}
          filterProps={{
            filters,
            filterParams: filtersValue,
            onClear: onFilterClear,
          }}
          getData={getTaskList}
          tableLabels={tableLabels}
          mapTableData={mapTableData}
          onClick={onClickItem}
        />
        <ModalActTaskDetail
          open={modalVisible}
          size={"xlarge"}
          data={taskSelected}
          onUserRedirect={() => setRedirectModalVisible(true)}
          onClose={onModalClose}
          onApprove={onApproveModalShow}
          onReject={() => setShowRejecting(true)}
        />
        <ModalActTaskRedirect
          open={redirectModalVisible}
          taskId={taskSelected?.id}
          companyId={taskSelected?.customer?.id}
          title={"Перенаправление задачи"}
          onClose={(val) => closeAllModals(val)}
        />
        <ModalActTaskApprove
          open={showApproveModal}
          taskId={taskSelected?.id}
          actId={taskSelected?.sourceId}
          title={"Согласование акта"}
          onClose={(val) => closeAllModals(val)}
        />
        <ModalActTaskReject
          open={showRejecting}
          taskId={taskSelected?.id}
          title={"Отклонение акта"}
          onClose={(val) => closeAllModals(val)}
        />
      </React.Fragment>
    </ConstructiveContext.Provider>
  );
}

export default ActTasks;
