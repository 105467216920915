import axios, { AxiosHeaders, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import CryptoJS from "crypto-js";
import { refreshTokens } from "./user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;

const axiosInstance = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/v1`,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
  },
});

// Ключи для шифрования
const AES_KEY = "SEBSyHfwCunvy+Cb2eVlYQ==";
const AES_IV = "R4WpY+21YETgcLPOWi2OzQ==";

function encryptHeaderValue(key: string): string {
  const currentDate = new Date().toISOString().split("T")[0]; // Текущая дата в формате YYYY-MM-DD
  const valueToEncrypt = `${key}.${currentDate}`;

  const encrypted = CryptoJS.AES.encrypt(
    valueToEncrypt,
    CryptoJS.enc.Base64.parse(AES_KEY),
    {
      iv: CryptoJS.enc.Base64.parse(AES_IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return encrypted.toString();
}

function instance(authorization = true, url?: string) {
  axiosInstance.interceptors.request.use(
    async (config) => {
      if (config.headers) {
        if (authorization) {
          (config.headers as AxiosHeaders).set(
            "Authorization",
            `Bearer ${Cookies.get("accessToken") || ""}`
          );
        }

        const encryptedValue = encryptHeaderValue("DFsdsdvzxv");

        // Добавляем зашифрованное значение в Header
        (config.headers as AxiosHeaders).set("Custom-Header", encryptedValue);
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  if (authorization) {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest: AxiosRequestConfig = error.config;

        if (error.response && error.response.status === 401) {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const refreshToken = Cookies.get("refreshToken") ?? "";
              const response = await refreshTokens({ refreshToken });
              const { token, refreshToken: newRefreshToken } = response;

              Cookies.set("accessToken", token);
              Cookies.set("refreshToken", newRefreshToken);

              error.config.headers["Authorization"] = `Bearer ${token}`;

              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosInstance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              refreshAndRetryQueue.length = 0;

              return axiosInstance(originalRequest);
            } catch (refreshError) {
              console.error("Token refresh failed:", refreshError);

              Cookies.remove("accessToken");
              Cookies.remove("refreshToken");
              refreshAndRetryQueue.length = 0;
              window.location.href = "/sign-in";
              return Promise.reject(refreshError);
            } finally {
              isRefreshing = false;
            }
          }

          return new Promise<void>((resolve, reject) => {
            refreshAndRetryQueue.push({
              config: originalRequest,
              resolve,
              reject,
            });
          });
        }

        return Promise.reject(error);
      }
    );
  }

  return axiosInstance;
}

export default instance;
