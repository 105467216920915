import BG from "../bg.png";
import BG1 from "./image_bg_1.png";
import BG2 from "./image_bg_2.png";
import BG3 from "./image_bg_3.png";
import BG4 from "./image_bg_4.png";
import BG5 from "./image_bg_5.png";
import BG7 from "./image_bg_7.png";
import BG8 from "./image_bg_8.png";
import BG9 from "./image_bg_9.png";
import BG10 from "./image_bg_10.png";
import BG11 from "./image_bg_11.png";
import BG12 from "./image_bg_12.png";
import BG13 from "./image_bg_13.png";

const slideList = [
  { path: BG, position: "center" },
  { path: BG1, position: "center" },
  { path: BG2, position: "right" },
  { path: BG3, position: "right" },
  { path: BG4, position: "right" },
  { path: BG5, position: "right" },
  { path: BG7, position: "center" },
  { path: BG8, position: "right" },
  { path: BG9, position: "right" },
  { path: BG10, position: "right" },
  { path: BG11, position: "right" },
  { path: BG12, position: "right" },
  { path: BG13, position: "left" },
];

export {
  BG,
  BG1,
  BG2,
  BG3,
  BG4,
  BG5,
  BG7,
  BG8,
  BG9,
  BG10,
  BG11,
  BG12,
  BG13,
  slideList,
};
