import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { BaseSchema, object, ValidationError } from "yup";

interface Props<T> {
  values: T;
  schema?: BaseSchema;
  onSubmit?: () => void;
}

type FormErrors<T> = {
  [key in keyof T]?: string;
};

export interface UseForm<T> extends Pick<Props<T>, "values"> {
  pending: boolean;
  errors: FormErrors<T>;
  setPending: Dispatch<SetStateAction<boolean>>;
  onChange: (value: T[keyof T], key: keyof T) => void;
  validate: (schema?: BaseSchema) => Promise<boolean>;
  setValues: (values: Partial<T>) => void;
  setErrorMessage: (message: FormErrors<T>) => void;
  resetForm: () => void;
}

export function useForm<T>(props: Props<T>): UseForm<T> {
  const { values: propsValues, schema, onSubmit } = props;
  const [pending, setPending] = useState<boolean>(false);
  const [values, setValues] = useState<T>(propsValues);
  const [errors, setErrors] = useState<FormErrors<T>>({});

  const updateValues = useCallback((partialValues: Partial<T>) => {
    setValues((prevValues) => ({
      ...prevValues,
      ...partialValues,
    }));
  }, []);

  const resetForm = useCallback(() => {
    setValues(propsValues);
    setErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateErrors = useCallback((message: FormErrors<T>) => {
    setErrors((prevValues) => ({
      ...prevValues,
      ...message,
    }));
  }, []);

  const onChange = useCallback(
    (value: T[keyof T], key: keyof T) => {
      if (!!value && errors[key]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: undefined,
        }));
      }

      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    },
    [errors]
  );

  const validate = useCallback(
    (schemaToValidate?: BaseSchema) => {
      try {
        (schema || schemaToValidate || object().shape({})).validateSync(
          values,
          {
            abortEarly: false,
          }
        );

        return Promise.resolve(true);
      } catch (e) {
        const tempErrors: FormErrors<T> = {};

        (e as ValidationError).inner.forEach((error: ValidationError) => {
          tempErrors[error.path as keyof T] = error.errors[0];
        });

        setErrors(tempErrors);

        return Promise.resolve(false);
      }
    },
    [schema, values]
  );

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (!!onSubmit && event.key === "Enter") {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [onSubmit]);

  return {
    pending,
    values,
    errors,
    onChange,
    setPending,
    setErrorMessage: updateErrors,
    validate,
    setValues: updateValues,
    resetForm,
  };
}
