import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@app/components";
import { IconLogo } from "@app/icons";
import { FormSignIn } from "@app/forms";
import { LayoutAuth } from "@app/layouts";
import styled from "styled-components";

interface Props {}

const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media only screen and (max-width: 576px) {
    width: 100%;
    background-size: cover;
  }
`;

const StyledLink = styled.a`
  color: #1c2246;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

function PageSignIn(_props: Props) {
  const navigate = useNavigate();

  return (
    <LayoutAuth>
      <StyledContent>
        <StyledLink href="/">
          <IconLogo />
        </StyledLink>
        <h2>Вход</h2>
        <FormSignIn />
        <StyledLink href="/sign-up">Зарегистрироваться</StyledLink>
        <Button
          text={"На Главную страницу"}
          onClick={() => navigate("/")}
          variant={"newDesign"}
        />
      </StyledContent>
    </LayoutAuth>
  );
}

export default memo(PageSignIn);
