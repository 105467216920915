import React, { useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Button, Grid, Loader, Tabs } from "@app/components";
import { IconStatistic24 } from "@app/icons";
import { VehicleTrack } from "@app/models";
import { switchProp, theme } from "styled-tools";
import { dateUTCFormat } from "@app/helpers";
import VehicleHistory from "../VehicleHistory";
import Panel from "../Panel";
import Info from "../Info";
import { useMonitoring } from "@app/providers";

interface Props {
  vehicle: VehicleTrack | null;
}

const StyledStatistics = styled.div`
  position: relative;
  zoom: 80%;
`;

const StyledPaper = styled.div<{
  visible: boolean;
}>`
  position: absolute;
  right: 0;

  ${switchProp("visible", {
    true: css`
      visibility: visible;
    `,
    false: css`
      visibility: hidden;
      height: 0;
      width: 0;
    `,
  })}
`;

const StyledContent = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background-color: ${theme("color.white")};
  overflow: auto;
  max-height: 500px;
`;

const StyledLoaderContainer = styled.div`
  text-align: center;
`;

export default function (props: Props) {
  const { vehicle } = props;
  const { vehicleReport, setVehicleReport } = useMonitoring();
  const [paperVisible, setPaperVisible] = useState(true);
  const [pending, setPending] = useState(true);

  const onClickShowHide = useCallback(() => {
    setPaperVisible((prevPaperVisible) => !prevPaperVisible);
  }, []);

  // const getData = useCallback(async () => {
  //   try {
  //     setPending(true);
  //     if (vehicle) {
  //       const { id, dateMin, dateMax } = vehicle;
  //
  //       const minDate = formatToUTCString(dateMin as Date);
  //       const maxDate = formatToUTCString(dateMax as Date);
  //
  //       const { data } = await getVehicleReport(id, {
  //         fromDateTime: minDate,
  //         toDateTime: maxDate,
  //       });
  //
  //       setVehicleReport(data);
  //     }
  //     setPending(false);
  //   } catch (e) {
  //     setPending(false);
  //     console.error(e);
  //   }
  // }, [vehicle, setVehicleReport]);

  const title = useMemo(() => {
    if (vehicle) {
      const companiesLength = vehicle.companies.length;

      if (companiesLength === 0) {
        return "";
      }

      if (companiesLength === 1) {
        return vehicle.companies[0].name;
      }

      return vehicle.companies.reduce(
        (accumulator, currentValue, currentIndex) =>
          `${accumulator} ${currentIndex > 0 ? ", " : ""} ${
            currentValue.name
          }`.trim(),
        ""
      );
    } else if (vehicleReport) {
      return vehicleReport.title;
    } else {
      return "";
    }
  }, [vehicle, vehicleReport]);

  const tabLabels = useMemo(
    () => ["Статистика", "История найма", "Данные ГСМ"],
    []
  );

  // useEffect(() => {
  //   getData();
  // }, [getData]);

  return (
    <StyledStatistics>
      <Button
        startIcon={IconStatistic24}
        variant="outlined"
        onClick={onClickShowHide}
      />
      <StyledPaper visible={paperVisible}>
        <Panel
          title={title}
          small={vehicle ? vehicle.companies.length > 1 : false}
          excerpt={vehicle ? vehicle.govNumber : ""}
          text={
            vehicle ? `${vehicle.vehicleType} - ${vehicle.characteristics}` : ""
          }
        >
          <Tabs labels={tabLabels} transparent={true}>
            <StyledContent>
              {pending && (
                <StyledLoaderContainer>
                  <Loader size="small" />
                </StyledLoaderContainer>
              )}
              {!!vehicleReport && (
                <Grid gap={16}>
                  <Info
                    label="Начало смены:"
                    value={
                      !!vehicleReport.shiftStart
                        ? dateUTCFormat(
                            vehicleReport.shiftStart,
                            "dd.MM.yyyy HH:mm"
                          )
                        : "-"
                    }
                  />
                  <Info
                    label="Окончание смены:"
                    value={
                      !!vehicleReport.shiftEnd
                        ? dateUTCFormat(
                            vehicleReport.shiftEnd,
                            "dd.MM.yyyy HH:mm"
                          )
                        : "-"
                    }
                  />
                  <Info
                    label="Длительность смены:"
                    value={vehicleReport.shiftDuration || "-"}
                  />
                  <Info
                    label="Время работы двигателя:"
                    value={vehicleReport.shiftEngineOperationTime || "-"}
                  />
                  <Info
                    label="Время работы двигателя под нагрузкой:"
                    value={vehicleReport.shiftEngineWorkTime || "-"}
                  />
                  <Info
                    label="Время работы двигателя в холостую:"
                    value={vehicleReport.shiftEngineIdleTime || "-"}
                  />
                  <Info
                    label="Время двигателя в выкл состоянии:"
                    value={vehicleReport.engineOffHours || "-"}
                  />
                  <Info
                    label="Время движения:"
                    value={vehicleReport.shiftTravelTime || "-"}
                  />
                  <Info
                    label="Пробег за период:"
                    value={
                      vehicleReport.shiftMilleage
                        ? `${vehicleReport.shiftMilleage.toFixed(2)} км`
                        : "-"
                    }
                  />
                </Grid>
              )}
            </StyledContent>
            <StyledContent>
              {!!vehicle && (
                <VehicleHistory id={vehicle.id} date={vehicle.dateMax} />
              )}
            </StyledContent>
            <StyledContent>
              {pending && (
                <StyledLoaderContainer>
                  <Loader size="small" />
                </StyledLoaderContainer>
              )}
              {!!vehicleReport && (
                <Grid gap={16}>
                  <Info
                    label="Начало смены:"
                    value={
                      !!vehicleReport.shiftStart
                        ? dateUTCFormat(
                            vehicleReport.shiftStart,
                            "dd.MM.yyyy HH:mm"
                          )
                        : "-"
                    }
                  />
                  <Info
                    label="Окончание смены:"
                    value={
                      !!vehicleReport.shiftEnd
                        ? dateUTCFormat(
                            vehicleReport.shiftEnd,
                            "dd.MM.yyyy HH:mm"
                          )
                        : "-"
                    }
                  />
                  <Info
                    label="Начальный обьем, л"
                    value={vehicleReport.startFuelVolume?.toString() || "-"}
                  />
                  <Info
                    label="Конечный обьем, л"
                    value={vehicleReport.endFuelVolume?.toString() || "-"}
                  />
                  <Info
                    label="Фактический расход, л"
                    value={vehicleReport.fuelConsumption?.toString() || "-"}
                  />
                  <Info
                    label="Обьем заправок, л"
                    value={vehicleReport.shiftFueled?.toString() || "-"}
                  />
                  <Info
                    label="Обьем сливов, л"
                    value={vehicleReport.drainVolume?.toString() || "-"}
                  />
                </Grid>
              )}
            </StyledContent>
          </Tabs>
        </Panel>
      </StyledPaper>
    </StyledStatistics>
  );
}
